import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {UserApi} from "../api";


const initialState = {
    user: {},
    userEvents: [],
    userEventsLoading: false,
    loading: false,
    loginLoading: false,
    userLoggedIn: false,
    loginError: null,
    selectedEventId: null,
}

export const createUser = createAsyncThunk('user/create', async (fields) => {
    return await UserApi.create(fields)
})

export const loginUser = createAsyncThunk('user/loginUser', async ({email, password}) => {
    return await UserApi.authenticate(email, password)
})

export const logoutUser = createAsyncThunk('user/logoutUser', async () => {})

export const doGetUser = createAsyncThunk('user/getUser', async () => {
    return await UserApi.get_user()
})

export const doGetUserEvents = createAsyncThunk('user/getUserEvents', async () => {
    return await UserApi.get_user_events()
})

export const setSelectedEvent = createAsyncThunk('user/setSelectedEvent', async event_id => {
    return event_id
})

const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(createUser.pending, (state, action) => {
                state.loading = true
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.loading = false
            })

            .addCase(doGetUser.fulfilled, (state, action) => {
                state.user = action.payload
            })

            .addCase(doGetUserEvents.pending, (state, action) => {
                state.userEventsLoading = true
            })
            .addCase(doGetUserEvents.fulfilled, (state, action) => {
                state.userEventsLoading = false
                if (state.userEvents.length > 0) return;
                state.userEvents = action.payload
            })

            .addCase(logoutUser.fulfilled, (state, action) => {
                localStorage.removeItem('token')
                localStorage.removeItem('tokenExpires')
                state.loading = false
                state.userLoggedIn = false
                state.userEvents = []
                state.selectedEventId = null;
            })

            .addCase(loginUser.pending, (state, action) => {
                state.loading = true
                state.loginLoading = true
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loginError = {
                    name: action.error.name,
                    message: action.error.message,
                }
                state.loading = false
                state.loginLoading = false
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                const {token, expires, user} = action.payload
                localStorage.setItem('token', token)
                localStorage.setItem('tokenExpires', expires)

                state.loading = false
                state.loginLoading = false
                state.userLoggedIn = true
                state.user = user
            })
            .addCase(setSelectedEvent.fulfilled, (state, action) => {
                state.selectedEventId = action.payload
            })
    }
})

export default userSlice.reducer

export const selectUser = state => {
    return state.user.user
}

export const selectAllUserEvents = state => {
    return state.user.userEvents
}


export const selectUserEvent = state => {
    return state.user.userEvents.length > 0 ? state.user.userEvents[0] : null
}

export const selectUserLoading = state => {
    return state.user.loading
}

export const selectUserEventsLoading = state => {
    return state.user.userEventsLoading
}

export const selectLoginLoading = state => {
    return state.user.loginLoading
}

export const selectUserError = state => {
    return state.user.loginError
}

export const selectUserLoggedIn = state => {
    return state.user.userLoggedIn
}

export const selectSelectedEvent = state => {
    if (!state.user.selectedEventId || state.user.userEvents.length === 0) return null;
    return state.user.userEvents.find(e => e.id === state.user.selectedEventId)
}
