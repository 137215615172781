import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {stationAPI, TableApi} from "../api";

const initialState = {
    stationAssignments: {},
    uncommittedChanges: {},
    loading: false,
}

export const setLocalChanges = createAsyncThunk('station/localChange',
    async (payload) => {
        return payload
    })

export const doInsertOrUpdateAssignment = createAsyncThunk('station/makeAssignment',
    async ({
               event_id,
               station_identifier,
               assigned,
           }) => {
        return await stationAPI.create_or_update_assignment(event_id, station_identifier, assigned)
    })

export const doInsertOrUpdateAssignmentBatch = createAsyncThunk('station/makeAssignmentBatch',
    async ({
               event_id,
               updated,
           }) => {
        await stationAPI.create_or_update_assignment_batch(event_id, updated).then(_ => updated)
    })

export const doFetchStationAssignments = createAsyncThunk('station/doFetchStationAssignments',
    async (event_id) => {
        return await stationAPI.list_by_event_id(event_id)
    })


const stationSlice = createSlice({
    name: 'station',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(setLocalChanges.fulfilled, (state, action) => {
                state.uncommittedChanges = {...state.uncommittedChanges, ...action.payload}
            })
            .addCase(doInsertOrUpdateAssignmentBatch.fulfilled, (state, action) => {
                state.stationAssignments = {...state.stationAssignments, ...state.uncommittedChanges}
                state.uncommittedChanges = {}
            })
            .addCase(doInsertOrUpdateAssignment.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doInsertOrUpdateAssignment.fulfilled, (state, action) => {
                const {station_identifier, assigned} = action.payload
                state.loading = false
                state.stationAssignments = {...state.stationAssignments, [station_identifier]: assigned}
                delete state.uncommittedChanges[station_identifier]
            })
            .addCase(doFetchStationAssignments.fulfilled, (state, action) => {
                action.payload.forEach(({station_identifier, assigned}) => {
                    state.stationAssignments[station_identifier] = assigned
                })
            })
    }
})

export default stationSlice.reducer

export const selectAllStationAssignments = state => {
    return state.station.stationAssignments
}

export const selectLocalStationChanges = state => {
    return state.station.uncommittedChanges
}
