import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {TableApi} from "../api";

const initialState = {
    tables: [],
    loading: false,
    editing_table: {},
    editing_table_id: null,
    hovering_table_id: null,
}

export const doCreateTable = createAsyncThunk('table/createTable', async ({
                                                                              room_id,
                                                                              name,
                                                                              max_seating,
                                                                              width,
                                                                              length,
                                                                              type,
                                                                              position,
    rotation,
                                                                          }) => {
    return await TableApi.create(room_id, name, max_seating, width, length, type, position, rotation)
})

export const doSeatGuests = createAsyncThunk('table/doSeatGuest', async ({table_id, guest_ids}) => {
    return TableApi.seat_guests(table_id, guest_ids)
})

export const doUnseatGuests = createAsyncThunk('table/doSeatGuest', async ({guest_ids}) => {
    return TableApi.unseat_guests(guest_ids)
})

export const doFetchRoomTables = createAsyncThunk('table/fetchRoomTables', async (room_id) => {
    return await TableApi.list_tables_for_room(room_id)
})

export const doUpdateTable = createAsyncThunk('table/update', async ({table_id, values}) => {
    console.log({
        table_id,
        values
    })
    return TableApi.update_table(table_id, values)
})

export const doDeleteTable = createAsyncThunk('table/delete', async (table_id) => {
    return new Promise(async res => {
        await TableApi.delete_table(table_id)
        res(table_id)
    })
})

export const doSetTableLocally = createAsyncThunk('table/doSetTableLocally', async ({table_id, values}) => {
    return {table_id, values}
})

export const doSetEditingTable = createAsyncThunk('table/doSetEditingTable', async (table_id) => {
    return table_id
})

export const doSetStopHoveringTable = createAsyncThunk('table/doSetStopHoveringTable', async (table_id) => {
    return table_id
})
export const doSetHoveringTable = createAsyncThunk('table/doSetHoveringTable', async (table_id) => {
    return table_id
})

const tableSlice = createSlice({
    name: 'table',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(doCreateTable.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doCreateTable.fulfilled, (state, action) => {
                state.tables = [...state.tables, action.payload]
                state.loading = false
            })

            .addCase(doSeatGuests.fulfilled, (state, action) => {
                const updated_tables = action.payload
                state.tables = state.tables.map(t => !(t.id in updated_tables) ? t : {
                    ...t,
                    seats: updated_tables[t.id]
                })
            })

            .addCase(doFetchRoomTables.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchRoomTables.fulfilled, (state, action) => {
                console.log('tables', action.payload)
                state.tables = action.payload
                state.loading = false
            })

            .addCase(doUpdateTable.fulfilled, (state, action) => {
                const updated = action.payload
                state.tables = state.tables.map(e => e.id === updated.id ? updated : e)
            })

            .addCase(doDeleteTable.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doDeleteTable.fulfilled, (state, action) => {
                const deleted_id = action.payload
                state.tables = state.tables.filter(e => e.id !== deleted_id)
                state.loading = false

                if (state.editing_table_id === deleted_id) {
                    state.editing_table_id = null;
                    state.editing_table = null;
                }
            })

            .addCase(doSetTableLocally.fulfilled, (state, action) => {
                const {table_id, values} = action.payload
                state.tables = state.tables.map(existing =>
                    existing.id === table_id ?
                        {...existing, ...values, dirty: {...(existing.dirty || {}), ...values}}
                        : existing)

                if (table_id === state.editing_table_id) {
                    state.editing_table = {...state.editing_table, ...values}
                }
            })

            .addCase(doSetEditingTable.fulfilled, (state, action) => {
                const table_id = action.payload
                if (table_id === null) {
                    state.editing_table = null;
                    state.editing_table_id = null;
                    return;
                }
                const t = state.tables.find(t => t.id === table_id)
                state.editing_table = t
                state.editing_table_id = t.id
            })

            .addCase(doSetHoveringTable.fulfilled, (state, action) => {
                state.hovering_table_id = action.payload
            })
            .addCase(doSetStopHoveringTable.fulfilled, (state, action) => {
                if (state.hovering_table_id === action.payload) {
                    state.hovering_table_id = null
                }
            })
    }
})

export default tableSlice.reducer

export const selectAllTables = state => {
    return [...state.table.tables].sort((a, b) => parseInt(a.name) - parseInt(b.name))
}

export const selectAllSeated = state => {
    return state.table.tables.map(t => t.seats).flat()
}

export const selectTableLoading = state => {
    return state.table.loading
}

export const selectTableById = table_id => state => {
    if (!table_id) return null;
    return state.table.tables.find(t => t.id === table_id)
}

export const selectEditingTableId = state => {
    return state.table.editing_table_id
}

export const selectEditingTable = state => {
    return state.table.editing_table
}

export const selectHoveringTableId = state => {
    return state.table.hovering_table_id
}

