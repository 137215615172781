import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {adminAPI, AdminAPI, UserApi} from "../api";

const initialState = {
    users: [],
    allVenues: [],
    loading: false,
    selectedUser: null,
}

export const doFetchAdminUsers = createAsyncThunk('admin/doFetchUsers', async () => {
    return await UserApi.list_all_users()
})
export const doFetchAdminVenues = createAsyncThunk('admin/doFetchVenues', async () => {
    return await adminAPI.list_all_venues()
})
export const doFetchAdminEvents = createAsyncThunk('admin/doFetchEvents', async () => {
    return await adminAPI.list_all_events()
})

export const doDeleteUser = createAsyncThunk('admin/doDeleteUser', async (user_id) => {
    await adminAPI.delete_user(user_id)
    return await UserApi.list_all_users()
})

export const doSelectUser = createAsyncThunk('admin/doSelectUser', async (user) => {
    console.log('Selecting user')
    return new Promise((res, rej) => {
        Promise.all([
            adminAPI.list_events_by_user_id(user.id),
            adminAPI.list_user_venues(user.id)
        ]).then(values => {
            console.log('promise all done.')
            console.log(values)
            const [user_events, user_venues] = values
            res({
                user_events,
                user_venues,
                ...user
            })
        }).catch(e => {
            console.log(e)
            rej(e)
        })
    })
})

export const doAddUserToVenue = createAsyncThunk('admin/doAddUserToVenue', async ({user_id, venue_id}) => {
    const user_venues = await adminAPI.add_user_to_venue(user_id, venue_id)
    const user_events = await adminAPI.list_events_by_user_id(user_id)

    return {user_venues, user_events}
})
export const doRemoveUserFromVenue = createAsyncThunk('admin/doRemoveUserFromVenue', async ({user_id, venue_id}) => {
    const user_venues = await adminAPI.remove_user_from_venue(user_id, venue_id)
    const user_events = await adminAPI.list_events_by_user_id(user_id)

    return {user_venues, user_events}
})


const adminSlice = createSlice({
    name: 'admin',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(doFetchAdminUsers.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchAdminUsers.fulfilled, (state, action) => {
                state.users = action.payload
                state.loading = false
            })

            .addCase(doFetchAdminVenues.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchAdminVenues.fulfilled, (state, action) => {
                state.venues = action.payload
                state.loading = false
            })

            .addCase(doFetchAdminEvents.fulfilled, (state, action) => {
                state.events = action.payload
            })

            .addCase(doSelectUser.fulfilled, (state, action) => {
                state.selected_user = action.payload
                state.loading = false
            })

            .addCase(doDeleteUser.fulfilled, (state, action) => {
                state.selected_user = null
                state.users = action.payload
                state.loading = false
            })

            .addCase(doAddUserToVenue.fulfilled, (state, action) => {
                const {user_venues, user_events} = action.payload
                state.selected_user = {
                    ...state.selected_user,
                    user_venues,
                    user_events,
                }
                state.loading = false
            })

            .addCase(doRemoveUserFromVenue.fulfilled, (state, action) => {
                const {user_venues, user_events} = action.payload
                state.selected_user = {
                    ...state.selected_user,
                    user_venues,
                    user_events,
                }
                state.loading = false
            })

    }
})

export default adminSlice.reducer

export const selectAdminUsers = state => {
    return state.admin.users
}

export const selectAdminVenues = state => {
    return state.admin.venues
}

export const selectAdminEvents = state => {
    return state.admin.events
}

export const selectSelectedUser = state => {
    return state.admin.selected_user
}
