import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EventApi, UserApi, VenueAPI} from "../api";

const initialState = {
    all_events: [],
    rooms: [],
    loading: false,
    creatingEvent: false,
    currentEvent: {},
    eventUsers: {},
}


export const doCreateEvent = createAsyncThunk('event/create', async values => {
    return EventApi.create(values)
})
export const doFetchEvent = createAsyncThunk('event/fetch', async event_id => {
    return EventApi.get_by_id(event_id)
})
export const doFetchEventRooms = createAsyncThunk('event/doFetchEventsRooms', async event_id => {
    return EventApi.list_rooms_by_event_id(event_id)
})
export const updateEvent = createAsyncThunk('event/update', async ({event_id, values}) => {
    return EventApi.update(event_id, values)
})
export const doListUsersByEvent = createAsyncThunk('event/listUsers', async event_id => {
    const users = await EventApi.list_users_by_event_id(event_id)
    return {event_id, users}
})
export const doCreateEventUser = createAsyncThunk('user/createEventUser', async (fields) => {
    const user = await UserApi.create_event_user(fields)
    return {event_id: fields.event_id, user}
})

export const doFetchVenueEvents = createAsyncThunk('venue/doFetchVenueEvents', async venue_id => {
    return await VenueAPI.list_venue_events(venue_id)
})


const eventSlice = createSlice({
    name: 'event',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(doCreateEvent.pending, (state, action) => {
                state.creatingEvent = true
            })
            .addCase(doCreateEvent.rejected, (state, action) => {
                state.creatingEvent = false
            })
            .addCase(doCreateEvent.fulfilled, (state, action) => {
                const createdEvent = action.payload
                state.all_events = [...state.all_events, createdEvent]
                state.creatingEvent = false
            })

            .addCase(doFetchEvent.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchEvent.fulfilled, (state, action) => {
                const ev = action.payload
                const exists = state.all_events.find(e => e.id === ev.id)
                if (!exists) {
                    state.all_events = [...state.all_events, ev]
                }

                state.currentEvent = ev
                state.loading = false
            })

            .addCase(doFetchVenueEvents.fulfilled, (state, action) => {
                state.all_events = action.payload
            })

            .addCase(doFetchEventRooms.fulfilled, (state, action) => {
                state.rooms = action.payload
                state.loading = false
            })

            .addCase(updateEvent.pending, (state, action) => {
                state.loading = true
            })
            .addCase(updateEvent.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(updateEvent.fulfilled, (state, action) => {
                const ev = action.payload
                state.all_events = state.all_events.map(e => e.id === ev.id ? ev : e)
                state.loading = false
            })

            .addCase(doListUsersByEvent.fulfilled, (state, action) => {
                const {event_id, users} = action.payload
                state.eventUsers = {...state.eventUsers, [event_id]: users}
            })
            .addCase(doCreateEventUser.fulfilled, (state, action) => {
                const {event_id, user} = action.payload
                state.eventUsers = {
                    ...state.eventUsers,
                    [event_id]: [...state.eventUsers[event_id], user]
                }
            })

    }
})

export default eventSlice.reducer

export const selectVenueEvents = venue_id => state => {
    return state.event.all_events.filter(ev => ev.venue_id === venue_id).sort((e1, e2) => new Date(e1.date)-new Date(e2.date))
}
export const selectEventLoading = state => {
    return state.event.loading
}
export const selectCreatingEvent = state => {
    return state.event.loading
}

export const selectEventRooms = state => {
    return state.event.rooms
}

export const selectEventById = event_id => state => {
    const curr = state.event.all_events.find(e => e.id === parseInt(event_id))
    return curr ?? null
}

export const selectUsersByEventId = event_id => state => {
    return state.event.eventUsers[event_id] ?? []
}

export const selectCurrentEvent = event_id => state => {
    event_id = parseInt(event_id)
    if (!state.event.currentEvent || event_id !== state.event.currentEvent.id) return null;
    return state.event.currentEvent
}
