import React, {useEffect, useRef, useState} from "react";
import clear from '../../assets/clear-24px.svg'
import arrowDown from '../../assets/arr_down_white.svg'
import './input-field.css'
import {useKeyboardShortcut} from "../../UseKeyboardShortcut";

export default function InputField(
    {
        title,
        type,
        options,
        placeholder,
        onChanged,
        value,
        onBlur,
        startWithFocus,
        style,
        className,
        onlySelectableOptions,
        error,
        disabled,
        displayClearIcon = false,
        canCreateNew = false,
        onCreatedNew,
        submit
    }) {
    const [focused, setFocused] = useState(false);
    const [searching, setSearching] = useState('')
    const inputRef = useRef()

    function proxyOnChanged(newValue, clicked) {
        if (canCreateNew && newValue.startsWith('+ Create: "')) {
            onCreatedNew(searching);
            setSearching('');
            return;
        }
        if (onlySelectableOptions) {
            if (clicked) {
                onChanged(newValue)
                setSearching('')
            } else {
                setSearching(newValue)
                onChanged('')
            }
        } else {
            onChanged(newValue)
        }
    }

    let opts;
    if (options) {
        opts = options
            .filter((o, idx) => o === '' || options.indexOf(o) === idx)
            .filter(o => !onlySelectableOptions || o.toString().toLowerCase().startsWith(searching.toLowerCase()))

        if (canCreateNew) {
            opts = [...opts, `+ Create: "${searching}"`]
        }
    }

    function enterClicked() {
        if (submit) submit()
        if (!focused || !opts) return;
        if (opts.length === 1) {
            proxyOnChanged(opts[0], true);
        }
    }

    useKeyboardShortcut({
        shortcutKeys: ['Enter'],
        callback: enterClicked,
        focused
    });

    useEffect(() => {
        if (startWithFocus && inputRef.current) {
            inputRef.current.focus()
        }
    }, [startWithFocus, inputRef])

    return <div className={`input-field ${className ? className : ''}`}
                style={style} onClick={e => e.stopPropagation()}>
        <div className={`input-wrapper ${disabled && 'disabled'}`}>
            {(title || error) &&
                <label className='title-label'>{title}{error && <label className='error'> - {error}</label>}</label>}
            <div className='input-field-wrapper'>
                <input type={type}
                       onFocus={_ => setFocused(true)}
                       onBlur={e => {
                           if (onBlur) onBlur()
                           setFocused(false)
                       }}
                       className={(onlySelectableOptions) ? 'non-selectable' : ''}
                       value={(onlySelectableOptions && !value) ? searching : value}
                       checked={!!value}
                       ref={inputRef}
                       placeholder={placeholder}
                       disabled={disabled}
                       onChange={e => {
                           e.preventDefault();
                           e.stopPropagation();
                           proxyOnChanged((type === 'checkbox' ? e.target.checked : e.target.value), false)
                       }}
                />
                {displayClearIcon && (value || searching) &&
                    <img onClick={_ => proxyOnChanged('', true)} className='input-field-icon' src={clear} alt='clear'/>}
                {onlySelectableOptions &&
                    <img onClick={_ => inputRef.current.focus()} className='input-field-icon' src={arrowDown} alt='open'/>}
            </div>
        </div>
        {opts && focused && <div className='option-wrapper'>
            {opts
                .map(o => <li key={o} onMouseDown={e => proxyOnChanged(o, true)} className='drop-option'>{o}</li>)}
        </div>}
    </div>
}
