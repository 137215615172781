import React, {useState} from 'react'
import './event-signup-admin.css'
import SimpleButton from "../../../component/simple-button/SimpleButton";
import AdminHeader from "../../admin-page-wrapper/admin-header/AdminHeader";
import SignupFormPage from "./signup-form-page/SignupFormPage";
import SignupInvitationList from "./signup-invitation-list/SignupInvitationList";


export default function EventSignupAdminPage() {
    const [subpage, setSubpage] = useState('Form')

    return (<div className={'event-signup-admin-page'}>
        <AdminHeader/>
        <div className='sub-page-list'>
            <SimpleButton value='Form' onClick={_ => setSubpage('Form')} disabled={subpage === 'Form'}/>
            <SimpleButton value='Invitations' onClick={_ => setSubpage('Invitations')} disabled={subpage==='Invitations'}/>
        </div>

        {subpage === 'Form' && <SignupFormPage/>}
        {subpage === 'Invitations' && <SignupInvitationList/>}
    </div>)
}
