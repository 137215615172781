import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EventApi, GuestAPI} from "../api";

const initialState = {
    guests: [],
    componentWithSeatingControl: null,
    selectedGuests: [],
    loading: false,
    creatingGuestLoading: false,
}

export const doDeleteGuest = createAsyncThunk('guest/delete', async (guest_id) => {
    await GuestAPI.deleteGuestById(guest_id)

    return guest_id
})

export const doCreateGuest = createAsyncThunk('guest/create', async (values) => {
    return await GuestAPI.createGuest(values)
})

export const doFetchEventGuests = createAsyncThunk('guest/fetch', async (event_id) => {
    return await GuestAPI.listEventGuests(event_id)
})

export const fetchUpdatedEventGuests = createAsyncThunk('guest/specificFetch', async (guest_ids) => {
    return GuestAPI.listEventGuestsById(guest_ids)
})

export const doSetSelectedGuests = createAsyncThunk('guest/setSelectedGuests', ({component_id, guest_ids}) => {
    return {component_id, guest_ids}
})

export const doDeleteAllEventGuests = createAsyncThunk('guest/doDeleteAllEventGuests', async event_id => {
    return await EventApi.delete_all_users_by_event_id(event_id)
})


const guestSlice = createSlice({
    name: 'guest',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(doFetchEventGuests.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchEventGuests.fulfilled, (state, action) => {
                state.guests = action.payload
                state.loading = false
            })

            .addCase(doCreateGuest.pending, (state, action) => {
                state.creatingGuestLoading = true
            })
            .addCase(doCreateGuest.fulfilled, (state, action) => {
                state.creatingGuestLoading = false
                state.guests = [...state.guests, action.payload]
            })

            .addCase(fetchUpdatedEventGuests.fulfilled, (state, action) => {
                const updatedGuests = action.payload
                const updatedMap = {}
                updatedGuests.forEach(g => {
                    updatedMap[g.id] = g
                })
                state.guests = state.guests.map(g => updatedMap[g.id] ?? g)
            })
            .addCase(doDeleteGuest.fulfilled, (state, action) => {
                const deleted_guest_id = action.payload
                state.guests = state.guests.filter(g => g.id !== deleted_guest_id)
            })
            .addCase(doSetSelectedGuests.fulfilled, (state, action) => {
                const {component_id, guest_ids} = action.payload
                state.selectedGuests = guest_ids
                state.componentWithSeatingControl = component_id
            })

            .addCase(doDeleteAllEventGuests.fulfilled, (state, action) => {
                state.guests = action.payload
            })
    }
})

export default guestSlice.reducer

export const selectGuests = state => {
    return state.guest.guests
}

export const selectGuestsByIds = guestIds => state => {
    if (!guestIds) return []
    return state.guest.guests.filter(g => guestIds.indexOf(g.id) !== -1)
}

export const selectCountByGuestIds = guestIds => state => {
    if (!guestIds) return []
    return state.guest.guests.map(g => guestIds.indexOf(g.id) === -1 ? 0 : g.count).reduce((a,b) => a+b, 0)
}

export const selectGuestLoading = state => {
    return state.guest.loading
}

export const selectCreatingGuestLoading = state => {
    return state.guest.creatingGuestLoading
}

export const selectSelectedGuestIds = component_id => state => {
    if (component_id !== state.guest.componentWithSeatingControl) {
        return []
    }
    return state.guest.selectedGuests
}

export const selectSelectedGuests = component_id => state => {
    if (component_id !== state.guest.componentWithSeatingControl) {
        return []
    }
    return state.guest.selectedGuests.map(guest_id => state.guest.guests.find(g => g.id === guest_id))
}

export const selectAllSelectedGuests = state => {
    return selectGuestsByIds(state.guest.selectedGuests)(state)
}
