import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {generatePath, Link, useParams} from "react-router-dom";
import {
    doFetchVenueById,
    doFetchVenueRooms,
    selectVenueById, selectVenueLoading,
    selectVenueRooms
} from "../../slice/venueSlice";
import SimpleButton from "../../component/simple-button/SimpleButton";
import './venue-page.css'
import CenteredPopup from "../../component/centered-popup/CenteredPopup";
import InputField from "../../component/input-field/InputField";
import {doCreateEvent, doFetchVenueEvents, selectCreatingEvent, selectVenueEvents} from "../../slice/eventSlice";
import LinkWrap from "../../component/LinkWrap/LinkWrap";
import RoomList from "../../component/room-list/RoomList";
import CreateRoom from "./create-room/CreateRoom";

export default function VenuePage() {
    const dispatch = useDispatch()
    let {venue_id} = useParams()
    venue_id = parseInt(venue_id)

    const venue = useSelector(selectVenueById(venue_id))
    const rooms = useSelector(selectVenueRooms(venue_id))
    const creatingEvent = useSelector(selectCreatingEvent)
    const venueLoading = useSelector(selectVenueLoading);

    useEffect(() => {
        dispatch(doFetchVenueRooms(venue_id))
    }, [dispatch, venue_id])

    // new room
    const [creatingNewRoom, setCreatingNewRoom] = useState(false);

    // new event creation
    const [creatingNewEvent, setCreatingNewEvent] = useState(false)
    const [eventTitle, setEventTitle] = useState('')
    const [contactName, setContactName] = useState('')
    const [contactPhone, setContactPhone] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [eventDate, setEventDate] = useState('')
    const [expectedGuests, setExpectedGuests] = useState('')
    const [eventRooms, setEventRooms] = useState([]);
    const [localEventLoading, setLocalEventLoading] = useState(false);

    useEffect(() => {
        if (localEventLoading && !creatingEvent) {
            setLocalEventLoading(false);
            setCreatingNewEvent(false);
        }
    }, [localEventLoading, creatingEvent])

    const createEvent = useCallback(() => {
        setLocalEventLoading(true)
        dispatch(doCreateEvent({
            venue_id,
            title: eventTitle,
            contact_name: contactName,
            contact_phone: contactPhone,
            contact_email: contactEmail,
            date: eventDate,
            expected_guests: expectedGuests,
            room_ids: eventRooms
        }))
    }, [dispatch, venue_id, eventTitle, contactName, contactPhone, contactEmail, eventDate, expectedGuests, eventRooms]);

    if (!venue) return <p>loading</p>

    return (<div className={'venue-page'}>
        <CreateRoom venue_id={venue_id} creatingNewRoom={creatingNewRoom} setCreatingNewRoom={setCreatingNewRoom}/>

        <main>
            <div className='controls'>
                <div className='name-wrapper'>
                    <h1>{venue.name}</h1>
                    <h5>{venue.address}</h5>
                </div>
            </div>

            <div className='bottom-area'>
                <SimpleButton value='New event' onClick={_ => setCreatingNewEvent(true)}/>
                <SimpleButton value='New room' onClick={_ => setCreatingNewRoom(true)}/>
                <div className='rooms'>
                    <RoomList rooms={rooms} />
                </div>
            </div>
        </main>

        <CenteredPopup showPopup={creatingNewEvent} closePopup={setCreatingNewEvent}>
            <h1>New Event</h1>
            <InputField value={eventTitle} title={'Event Title'} onChanged={setEventTitle}/>
            <InputField value={eventDate} type='date' title={'Event Date'} onChanged={setEventDate}/>
            <InputField value={expectedGuests} title={'Expected Guests'} onChanged={setExpectedGuests}/>

            <div className='room-selection'>
                {rooms.map(room => <p className={`room ${eventRooms.indexOf(room.id) !== -1 && 'selected'}`}
                                      key={room.id}
                                      onClick={_ => setEventRooms(prev => (prev.indexOf(room.id) !== -1) ? prev.filter(e => e !== room.id) : [...prev, room.id])}>
                    {room.name}
                </p>)}
            </div>

            <SimpleButton disabled={eventRooms.length !== 1} value='CREATE' big onClick={createEvent} loading={creatingEvent}/>
        </CenteredPopup>

    </div>)
}
