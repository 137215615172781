import axios from 'axios';


const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http:///localhost:5000',
    timeout: 10000
})

http.interceptors.request.use((config) => {
    if (config.url === '/user/authenticate') return config;

    const token = localStorage.getItem('token')
    if (token) {
        const expires = localStorage.getItem('tokenExpires')
        if (expires) {
            if (new Date(expires) < new Date()) {
                localStorage.removeItem('token')
                localStorage.removeItem('tokenExpires')
                throw new axios.Cancel('Token expired. Moving to login.');
            }
        }
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
})

http.interceptors.response.use(null, err => {
    if (err && err.response && err.response.status === 401) {
        window.location = '/logout'
    }
    throw err
});


const easyUnpack = ({data}) => data

const formatError = (response) => {
    const error = new Error(
        response.message ||
        'An unknown error occured'
    )
    error.payload = response.message

    throw error
}

export const UserApi = {
    create({name, email, phone, role, password}) {
        return http.post(`/user`, {
            name,
            email,
            phone,
            role,
            password,
        }).then(easyUnpack)
    },
    create_event_user(values) {
        return http.post(`/user/event-user`, values).then(easyUnpack)
    },
    update(user_id, new_values) {
        return http
            .patch(`/user/${user_id}`, new_values).then(easyUnpack)
    },
    authenticate(email, password) {
        return http
            .post(`/user/authenticate`, {
                email,
                password
            })
            .then(easyUnpack)
            .catch(formatError)
    },
    get_user() {
        return http.get('/user').then(easyUnpack)
    },
    list_all_users() {
        return http.get('/user/list').then(easyUnpack)
    },
    get_user_events() {
        return http.get('/user/event').then(easyUnpack)
    }
}

export const EventApi = {
    create(values) {
        return http.post(`/event`, values).then(easyUnpack)
    },
    update(event_id, new_values) {
        return http
            .patch(`/event/${event_id}`, new_values).then(easyUnpack)
    },
    delete(event_id) {
        return http
            .delete(`/event/${event_id}`)
            .then(easyUnpack)
    },
    list_rooms_by_event_id(event_id) {
        return http.get(`/event/${event_id}/room`).then(easyUnpack)
    },
    get_by_id(event_id) {
        return http.get(`/event/${event_id}`).then(easyUnpack)
    },
    list_seating_overview(event_id) {
        return http.get(`/event/${event_id}/seating-overview`).then(easyUnpack)
    },
    list_dietary_overview(event_id) {
        return http.get(`/event/${event_id}/dietary-overview`).then(easyUnpack)
    },
    list_users_by_event_id(event_id) {
        return http.get(`/event/${event_id}/users`).then(easyUnpack)
    },
    delete_all_users_by_event_id(event_id) {
        return http.delete(`/event/${event_id}/guests`).then(easyUnpack)
    }
}

export const TableApi = {
    list_tables_for_room(room_id) {
        return http
            .get(`/room/${room_id}/table`, {
                params: {
                    with_seating: true
                }
            })
            .then(easyUnpack)
    },
    seat_guests(table_id, guest_ids) {
        return http
            .post(`/table/${table_id}/seating`, {
                guest_ids
            })
            .then(easyUnpack)
    },
    unseat_guests(guest_ids) {
        return http
            .post(`/table/unseat`, {
                guest_ids
            })
            .then(easyUnpack)
    },
    create(room_id, name, max_seating, width, length, type, position, rotation) {
        return http.post(`/table`, {
            room_id,
            name,
            max_seating,
            type,
            active_seats: max_seating,
            position: {x: Math.floor(position.x), y: Math.floor(position.y)},
            rotation,
            width,
            length,
        }).then(easyUnpack)
    },
    update_table(table_id, new_values) {
        return http
            .patch(`/table/${table_id}`, new_values)
            .then(easyUnpack)
    },
    delete_table(table_id) {
        return http
            .delete(`/table/${table_id}`)
            .then(easyUnpack)
    }
}

export const GuestAPI = {
    parseExcelGuestList(file_name, file_data) {
        return http.post(`/guest/parse`, {
            file_name,
            file_data,
        }).then(easyUnpack)
    },
    createGuests(event_id, guests, headers, header_map) {
        return http.post('/guest/parse/import', {
            event_id,
            guests,
            headers,
            header_map,
        }).then(easyUnpack)
    },
    batchUpdateGuests(guest_value_map) {
        return http.patch('/guest/batch', {
            guest_value_map
        }).then(easyUnpack)
    },
    listEventGuests(event_id) {
        return http.get(`/event/${event_id}/guests`).then(easyUnpack)
    },
    listEventGuestsById(guest_ids) {
        return http.get(`/event/guests`, {
            params: {
                guest_ids: guest_ids.join(',')
            }
        }).then(easyUnpack)
    },
    deleteGuestById(guest_id) {
        return http.delete(`/guest/${guest_id}`).then(easyUnpack)
    },
    createGuest(values) {
        return http.post('/guest', values).then(easyUnpack)
    }
}

export const VenueAPI = {
    list_all_venues() {
        return http.get('/venue').then(easyUnpack)
    },
    get_venue_by_id(venue_id) {
        return http.get(`/venue/${venue_id}`).then(easyUnpack)
    },
    create(name, address) {
        return http.post('/venue', {
            name,
            address
        }).then(easyUnpack)
    },
    list_venue_rooms(venue_id) {
        return http.get(`/venue/${venue_id}/room`).then(easyUnpack)
    },
    list_venue_events(venue_id) {
        return http.get(`/venue/${venue_id}/event`).then(easyUnpack)
    },
    list_all_venue_clients(venue_id) {
        return http.get(`/venue/${venue_id}/all-clients`).then(easyUnpack)
    }
}

export const RoomAPI = {
    create({venue_id, name, position_x, position_y, width_meter, height_meter, image_name, image_data}) {
        return http.post('/room', {
            venue_id,
            name,
            position_x,
            position_y,
            width_meter,
            height_meter,
            image_name,
            image_data,
        }).then(easyUnpack)
    },
    get_room_by_id(room_id) {
        return http.get(`/room/${room_id}`).then(easyUnpack)
    },
    update_room(room_id, values) {
        const {name, width_meter, height_meter, pixel_pr_meter} = values
        console.log('pixel_pr_meter', pixel_pr_meter)
        console.log('VALUES', values)
        return http.patch(`/room/${room_id}`, {
            name, width_meter, height_meter, pixel_pr_meter,
        }).then(easyUnpack)
    }
}

export const adminAPI = {
    list_venue_users(venue_id) {
        return http.get(`/admin/venue/${venue_id}/users`).then(easyUnpack)
    },
    list_user_venues(user_id) {
        return http.get(`/admin/user/${user_id}/venues`).then(easyUnpack)
    },
    list_all_venues() {
        return http.get(`/admin/venues`).then(easyUnpack)
    },
    list_all_events() {
        return http.get(`/admin/events`).then(easyUnpack)
    },
    add_user_to_venue(user_id, venue_id) {
        return http.put(`/admin/user/${user_id}/venue/${venue_id}`).then(easyUnpack)
    },
    remove_user_from_venue(user_id, venue_id) {
        return http.delete(`/admin/user/${user_id}/venue/${venue_id}`).then(easyUnpack)
    },
    add_user_to_event(user_id, event_id) {
        return http.put(`/admin/user/${user_id}/event/${event_id}`).then(easyUnpack)
    },
    remove_user_from_event(user_id, event_id) {
        return http.delete(`/admin/user/${user_id}/event/${event_id}`).then(easyUnpack)
    },
    list_events_by_user_id(user_id) {
        return http.get(`/admin/user/${user_id}/events`).then(easyUnpack)
    },
    delete_user(user_id) {
        return http
            .delete(`/admin/user/${user_id}`)
            .then(easyUnpack)
    },
    patch_user(user_id, password=null) {
        return http.patch(`/admin/user/${user_id}`, {
            password,
        }).then(easyUnpack)
    },
}

export const exportAPI = {
    export_guest_list_by_event_id(event_id) {
        return http.get(`export/event/${event_id}/guest-list`).then(easyUnpack)
    },
    export_waiter_list_by_event_id(event_id) {
        return http.get(`export/event/${event_id}/waiter-list`).then(easyUnpack)
    },
};

export const invitationAPI = {
    get_invitation_by_token(token) {
        return http.get(`/invitation/${token}`).then(easyUnpack)
    },
    submit_answers(token, answers) {
        return http.post(`/invitation/${token}`, {answers}).then(easyUnpack)
    },
    mark_as_seen(token) {
        return http.post(`/invitation/${token}/seen`).then(easyUnpack)
    },
    list_invitations(event_id) {
        return http.get(`/invitation/event/${event_id}/overview`).then(easyUnpack)
    },
}


export const invitationAdminAPI = {
    create_question(event_id, question_text, question_type, choices) {
        return http.post(`/invitation/admin/${event_id}/question`, {
            question_text,
            question_type,
            choices,
        }).then(easyUnpack)
    },
    delete_question(event_id, question_id) {
        return http.delete(`/invitation/admin/${event_id}/question/${question_id}`).then(easyUnpack)
    },
    list_questions(event_id) {
        return http.get(`/invitation/admin/${event_id}/question`).then(easyUnpack)
    },
    update_question(event_id, question_id, values) {
        return http.patch(`/invitation/admin/${event_id}/question/${question_id}`, {
            values
        }).then(easyUnpack)
    },
    list_invitations(event_id) {
        return http.get(`/invitation/event/${event_id}`).then(easyUnpack)
    },
    create_invitation(event_id, email, guest_name) {
        return http.post(`/invitation/event/${event_id}`, {
            email,
            guest_name
        }).then(easyUnpack)
    }
};

export const stationAPI = {
    create_or_update_assignment(event_id, station_identifier, assigned) {
        return http.post(`/station/event/${event_id}`, {
            station_identifier,
            assigned
        }).then(easyUnpack)
    },
    create_or_update_assignment_batch(event_id, updated) {
        return http.post(`/station/event/${event_id}/batch`, {
            updated,
        }).then(easyUnpack)
    },
    list_by_event_id(event_id) {
        return http.get(`/station/event/${event_id}`).then(easyUnpack)
    }
}
