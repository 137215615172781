import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
    draggingData: null,
    search: '',
}

export const doSetDraggingData = createAsyncThunk('seating/setDragging', (d) => {
    return d
})

export const doSetSeatingSearch = createAsyncThunk('seating/setSeatingSearch', d => {
    return d
})

const seatingSlice = createSlice({
    name: 'seating',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(doSetDraggingData.fulfilled, (state, action) => {
                state.draggingData = action.payload
            })
            .addCase(doSetSeatingSearch.fulfilled, (state, action) => {
                state.search = action.payload
            })
    }
})

export default seatingSlice.reducer

export const selectDraggingData = state => {
    return state.seating.draggingData
}

export const selectSeatingSearch = state => {
    return state.seating.search
}
