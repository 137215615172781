import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    selectSelectedEvent,
} from "../../../slice/userSlice";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {
    doDeleteAllEventGuests, doDeleteGuest, doFetchEventGuests, doSetSelectedGuests, fetchUpdatedEventGuests,
    selectGuests,
    selectSelectedGuestIds
} from "../../../slice/guestSlice";
import ImportGuestList from "../../../component/import-guest-list/ImportGuestList";
import './guest-management-page.css'
import ClientHeader from "../client-header/ClientHeader";
import {useKeyboardShortcut} from "../../../UseKeyboardShortcut";
import deleteIcon from "../../../assets/bin.png";
import InputField from "../../../component/input-field/InputField";
import MultiInputField from "../../../component/multi-input-field/MultiInputField";
import HoverButton from "../../../component/hover-button/HoverButton";
import edit from "../../../assets/edit-white.svg";
import {GuestAPI} from "../../../api";
import {useNavigate} from "react-router-dom";

export default function GuestManagementPage() {
    const dispatch = useDispatch()
    const guests = useSelector(selectGuests)
    const selectedEvent = useSelector(selectSelectedEvent);

    const [search, setSearch] = useState('');
    const [sortByColumn, setSortByColumn] = useState('')
    const [ascDesc, setAscDesc] = useState(1)

    const [changes, setChanges] = useState({});

    const [editing, setEditing] = useState(false);

    const componentSeatingId = useMemo(() => {
        return Math.random()
    }, []);

    useEffect(() => {
        if (!selectedEvent) return;
        dispatch(doFetchEventGuests(selectedEvent.id))
    }, [dispatch, selectedEvent]);

    const selectedGuests = useSelector(selectSelectedGuestIds(componentSeatingId))

    const rowRefs = useRef({});

    useKeyboardShortcut({
        shortcutKeys: ['Escape'], keyUpCallback: () => {
            dispatch(doSetSelectedGuests({component_id: componentSeatingId, guest_ids: []}))
        }
    })

    function clickedSort(headerName) {
        if (headerName === '🍴') {
            headerName = 'dietary'
        }
        if (headerName === '♿') {
            headerName = 'wheelchairs'
        }
        if (headerName === sortByColumn) {
            if (ascDesc === -1) {
                setAscDesc(1);
                setSortByColumn('')
            } else {
                setAscDesc(-1);
            }
        } else {
            setSortByColumn(headerName)
            setAscDesc(1)
        }
    }

    const sort = useCallback((a, b) => {
        if (a[sortByColumn] === b[sortByColumn]) return 0;

        if (typeof (a[sortByColumn]) === 'string' || typeof (b[sortByColumn]) === 'string') {
            if (a[sortByColumn] === null) return -1 * ascDesc;
            if (b[sortByColumn] === null) return ascDesc;

            return a[sortByColumn].localeCompare(b[sortByColumn]) * ascDesc
        } else {
            if (a[sortByColumn] > b[sortByColumn]) {
                return -1 * ascDesc
            }
            if (a[sortByColumn] < b[sortByColumn]) {
                return ascDesc
            }
        }
        return 0
    }, [sortByColumn, ascDesc]);

    const updateGuestValue = useCallback((guest_id, key, new_value) => {
        setChanges(prev => ({...prev, [guest_id]: {...prev[guest_id], [key]: new_value}}))
    }, []);

    const searchMatch = useCallback(g => {
        if (!search) return true

        const terms = [g.name.toLowerCase(), (g.dietary ?? '').toLowerCase(), (g.wheelchairs ?? '').toLowerCase(), (g.company ?? '').toLowerCase()]

        const s = search.toLowerCase()
        return terms.some(t => t.indexOf(s) !== -1)
    }, [search]);

    const sortedGuests = useMemo(() => {
        if (sortByColumn === '') {
            return [...guests]
        } else {
            return [...guests].sort(sort)
        }
    }, [guests, sortByColumn, sort])

    const filteredGuests = useMemo(() => {
        return sortedGuests.filter(searchMatch)
    }, [sortedGuests,searchMatch])

    const headers = useMemo(() => ['name', 'company', 'dietary', 'comment', 'wheelchairs'], []);
    const navigate = useNavigate()

    return (<div className='guest-management-page'>
        <ClientHeader>
            {selectedEvent && <ImportGuestList linked_event_id={selectedEvent.id}/>}
            <SimpleButton style={{marginLeft: '16px'}} value='Download gæste template' onClick={_ => {
                window.open(`${(process.env.REACT_APP_API_URL || "http:///localhost:5000")}/guest/download-guest-template`, '_blank')
            }}/>
            <SimpleButton style={{marginLeft: '16px'}} value='Go to Seating' onClick={_ => {navigate('/seating')}}/>
            <div className='grower'/>
            <SimpleButton value='Delete all guests' red onClick={_ => {
                if (!window.confirm(`Delete ALL guests for ${selectedEvent.title}?`)) return;
                dispatch(doDeleteAllEventGuests(selectedEvent.id))
            }}/>
        </ClientHeader>

        <InputField
            className='search-bar'
            value={search}
            onChanged={setSearch}
            placeholder='Søg på navn eller firma'
        />
        <div className='guest-management-table-wrapper'>
            <table draggable={false}>
                <thead>
                <tr>
                    <th></th>
                    <th></th>
                    {headers.map(header => {
                        let translatedHeader = header
                        if (header === '🍴') translatedHeader = 'dietary'
                        if (header === '♿') translatedHeader = 'wheelchairs'

                        return <th style={{zIndex: 1}}
                                   key={translatedHeader}
                                   onClick={() => clickedSort(translatedHeader)}>
                            <label>{header}</label>
                            <svg
                                className={`arrow ${(sortByColumn === translatedHeader) ? 'focus' : ''} ${ascDesc === -1 ? "asc" : "desc"}`}
                                version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 330 330">
                                <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                        c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                        s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.394z"/>
                            </svg>
                        </th>
                    })}
                </tr>
                </thead>
                <tbody>
                {filteredGuests.map(guest => {
                    const selected = !editing && selectedGuests && selectedGuests.indexOf(guest.id) !== -1 ? 'selected' : ''
                    return <tr
                        className={`power-table-row ${selected}`}
                        ref={el => rowRefs ? rowRefs.current[guest.id] = el : []} key={guest.id}>
                        <td className='delete-icon-td'>
                            <img
                                onClick={_ => {
                                    if (window.confirm(`Delete guest with name: ${guest.name}?`)) {
                                        dispatch(doDeleteGuest(guest.id))
                                    }
                                }}
                                className='delete-icon'
                                src={deleteIcon}
                                key='delete'
                                alt={`delete guest with name: ${guest.name}`}/>
                        </td>

                        {!editing && <td className='ptd'>{guest.count}</td>}
                        {editing && <td className='ptd'><InputField value={changes[guest.id] ? changes[guest.id].count ?? (guest.count ?? '') : guest.count ?? ''} onChanged={v => {
                            updateGuestValue(guest.id, 'count', v)
                        }}/></td>}


                        {headers.map((header, idx) => {
                            if (!editing)
                                return <td className='ptd' key={idx}>{guest[header]}</td>

                            const changed = changes[guest.id]
                            let value = '';
                            if (changed) {
                                value = changes[guest.id][header] ?? (guest[header] ?? '')
                            } else {
                                value = guest[header] ?? ''
                            }

                            if (header !== 'wheelchairs') {
                                return <td className='ptd' key={idx}><InputField value={value} onChanged={v => {
                                    updateGuestValue(guest.id, header, v)
                                }}/></td>
                            }

                            if (guest['count'] === 1) {
                                return <td className='ptd' key={idx}>
                                    <input
                                        className='checkbox' type='checkbox'
                                        onChange={e => {
                                            const v = e.target.checked
                                            if (v) {
                                                updateGuestValue(guest.id, header, guest.name)
                                            } else {
                                                updateGuestValue(guest.id, header, '')
                                            }
                                        }}
                                        checked={!!value}/>
                                </td>
                            }

                            return <td className='ptd' key={idx}><MultiInputField
                                value={value}
                                onChanged={v => updateGuestValue(guest.id, header, v)}/>
                            </td>
                        })}
                    </tr>
                })}
                </tbody>
            </table>
            {editing && <SimpleButton className='save-button' big value='Save' onClick={_ => {
                if (Object.keys(changes).length === 0) {
                    setEditing(false)
                    return;
                }

                GuestAPI.batchUpdateGuests(changes).then(_ => {
                    dispatch(fetchUpdatedEventGuests(Object.keys(changes)));
                    setChanges({});
                    setEditing(false);
                })
            }}/>}
        </div>
        {!editing && <HoverButton className='edit-button' icon={edit} onClick={_ => setEditing(true)}/>}
    </div>)
}
