import React, {useState} from 'react'
import './click-to-show.css'
import CenteredPopup from "../centered-popup/CenteredPopup";
import SimpleButton from "../simple-button/SimpleButton";

export default function ClickToShow({buttonClassName, className, children, buttonText, onOpen}) {
    const [display, setDisplay] = useState(false);
    return (<div className='click-to-show'>
        <CenteredPopup showPopup={display} closePopup={setDisplay} className={className}>
            {children}
        </CenteredPopup>
        <SimpleButton className={buttonClassName} value={buttonText} onClick={_ => {
            setDisplay(true)

            if (onOpen) onOpen()
        }} />
    </div>)
}
