import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import './popup-seating-table.css'
import {selectCountByGuestIds, selectGuestsByIds} from "../../../slice/guestSlice";
import GuestList from "../../../component/guest-list/GuestList";
import {selectTableById, selectTableLoading} from "../../../slice/tableSlice";
import MovableWindow from "../../../component/movable-window/MovableWindow";


export default function PopupSeatingTable({table: linked_table, onClose}) {

    const table = useSelector(selectTableById(linked_table ? linked_table.id : null))
    const tableGuests = useSelector(selectGuestsByIds(table ? table.seats : []))
    const seatedCount = useSelector(selectCountByGuestIds(table ? table.seats : []))

    return (<MovableWindow
        visible={!!table}
        canClose
        onClose={onClose}
        title={table ? table.name : ''}
        className={'popup-seating-table'}>
        {table && <>
            <div className='seat-count-wrapper'>
                <label>Max: {table.active_seats}</label><label>seated: {seatedCount}</label>
            </div>

            <GuestList guests={tableGuests} displaySeated simpleView targetTableOnDrop={linked_table.id}/>
            {tableGuests.length === 0 && <p>No guests seated</p>}
        </>}
    </MovableWindow>)
}
