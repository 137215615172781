import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    doSetEditingTable,
    doSetHoveringTable, doSetStopHoveringTable,
    selectEditingTableId,
    selectHoveringTableId
} from "../../slice/tableSlice";
import Dragable from "../dragable-handle/Dragable";
import './room-table.css'

export default function RoomTable({table, room, setTable, copyTableForPlacement}) {
    const dispatch = useDispatch()
    const editingTableId = useSelector(selectEditingTableId);
    const hoveringTableId = useSelector(selectHoveringTableId)

    const hovering = editingTableId === table.id || hoveringTableId === table.id
    return (<Dragable
        onMouseEnter={_ => dispatch(doSetHoveringTable(table.id))}
        onMouseLeave={_ => dispatch(doSetStopHoveringTable(table.id))}
        key={table.id}
        className={`table ${table.type} ${table.dirty && 'dirty'} ${hovering ? 'hovering' : ''}`}
        position={table.position}
        rotation={table.rotation}
        style={{
            width: `${table.width * room.pixel_pr_meter}px`,
            height: `${table.length * room.pixel_pr_meter}px`,
            transform: `translateX(-50%) translateY(-50%) rotate(${table.rotation}deg)`,
            background: table.id === -1 ? 'blue' : 'white',
        }}
        is_active={!editingTableId}
        setPosition={p => setTable && setTable(table.id, {position: {x: p.x, y: p.y}})}>
        <span className={`table-name ${editingTableId === table.id ? 'editing' : ''}`}
              onMouseDown={e => e.stopPropagation()}
              onClick={e => {
                  if (e.altKey && copyTableForPlacement) {
                      copyTableForPlacement(table)
                      return;
                  }
                  dispatch(doSetEditingTable(table.id))
              }} style={{
            left: table.type === 'Square' ? `${table.width * room.pixel_pr_meter-2}px` : `${table.width * room.pixel_pr_meter * 0.5}px`,
        }}>
            {table.name}
        </span>
        <div className='seating-label-wrapper'>
        <label className='seating-label active_seats'>{table.active_seats}</label>
        <label className='seating-label max_seating'>{table.max_seating}</label>
        </div>
    </Dragable>)
}
