import React from 'react'
import './hover-button.css'


export default function HoverButton({text, icon, onClick, className}) {
    return (
        <div className={`hover-button ${className}`} onClick={onClick}>
            {text && <p>{text}</p>}
            {icon && <img src={icon} alt={'hover-button'} />}
        </div>)
}
