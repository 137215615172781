import React, {useCallback, useMemo, useRef, useState} from 'react'
import InputField from "../../component/input-field/InputField";
import {useSelector} from "react-redux";
import './guest-list.css'
import {selectAllSeated} from "../../slice/tableSlice";
import PowerTable from "../power-table/PowerTable";

export default function GuestList({
                                      guests,
                                      className=null,
                                      showSearch = false,
                                      displaySeated = false,
                                      disableDrag = false,
                                      height,
                                      simpleView = false,
                                      targetTableOnDrop,
                                      editable=false,
                                  }) {
    const [search, setSearch] = useState('');

    const rowRefs = useRef({});

    const seated = useSelector(selectAllSeated)

    const searchMatch = useCallback(g => {
        if (!search) return true

        const terms = [g.name.toLowerCase(), (g.dietary ?? '').toLowerCase(), (g.wheelchairs ?? '').toLowerCase(), (g.company ?? '').toLowerCase()]

        const s = search.toLowerCase()
        return terms.some(t => t.indexOf(s) !== -1)
    }, [search]);

    const filteredGuests = useMemo(() => {
        if (displaySeated) return guests.filter(searchMatch);
        return guests.filter(g => seated.indexOf(g.id) === -1).filter(searchMatch)
    }, [guests, displaySeated, seated, searchMatch])

    return (<div className={`guest-list ${className ?? ''}`}>
        {showSearch && <>
            <InputField
                className='search-bar'
                value={search}
                onChanged={setSearch}
                placeholder='Søg på navn eller firma'
            />
        </>
        }
        <div className='table-wrapper' style={{height: height}}>
            <PowerTable
                style={{height: height}}
                disableDrag={disableDrag}
                rows={filteredGuests}
                headers={['name', 'company', 'count', 'dietary', 'wheelchairs']}
                rowRefs={rowRefs}
                simpleView={simpleView}
                targetTableOnDrop={targetTableOnDrop}
                editable={editable}
            />
        </div>
    </div>)
}
