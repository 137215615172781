import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import './signup-page.css'
import InputField from "../../component/input-field/InputField";
import MultipleChoice from "../../component/multiple-choice/MultipleChoice";
import SimpleButton from "../../component/simple-button/SimpleButton";
import {useParams} from "react-router-dom";
import {invitationAPI} from "../../api";
import LoadingPage from "../../component/loading-page/LoadingPage";
import LoadingAnimation from "../../component/loading-animation/LoadingAnimation";
import {prettyDate} from "../../component/date_functions";


export default function SignupPage(props) {
    const [invitation, setInvitation] = useState(null);
    const [answers, setAnswers] = useState({});
    const [loading, setLoading] = useState(false);

    const {token} = useParams();

    useEffect(() => {
        if (!token) return;

        invitationAPI.get_invitation_by_token(token).then(setInvitation)
        invitationAPI.mark_as_seen(token)
    }, [token]);

    if (!invitation) {
        return <div className='signup-page'>
            <main>
                <LoadingPage/>
            </main>
        </div>
    }

    console.log(invitation)
    return (<div className='signup-page'>
        <main>
            <h1>Hej {invitation.guest_name},</h1>
            <p>Du er inviteret til <b>{invitation.event.title}</b> i {invitation.venue.name} <b>{prettyDate(invitation.event.date)} kl 18:00</b>!</p>
            <p>Adressen er: <b>{invitation.venue.address}</b></p>

            {invitation.questions.map(question => <div key={question.id} className='question'>
                <h3>{question.text}</h3>
                {question.type === 'text' ?
                    <input
                        type='text'
                        value={answers[question.id] ?? question.answer ?? ''}
                        onChange={v => setAnswers(prev => ({...prev, [question.id]: v.target.value}))}
                    /> :
                    <MultipleChoice
                        value={answers[question.id] ?? question.answer ?? ''}
                        onChanged={v => setAnswers(prev => ({...prev, [question.id]: v}))}
                        choices={question.choices}/>
                }
            </div>)}
            {loading && <LoadingAnimation />}
            <SimpleButton
                big
                value='Submit'
                loading={loading}
                onClick={_ => {
                    setLoading(true)
                    invitationAPI.submit_answers(token, answers).then(inv => {
                        setLoading(false)
                        setInvitation(inv)
                        setAnswers({})
                    })
                }}
            />
        </main>
    </div>)
}
