import React, {useCallback, useMemo, useState} from "react";
import MouseEventHandler from "../mouse-event-handler/MouseEventHandler";
import './dragable.css'
import {Vector} from "vector2d";
import {useSelector} from "react-redux";
import {selectZoom} from "../../slice/roomSlice";

export default function Dragable(props) {
    const {is_active, position, rotation, style, setPosition, className, children} = props
    const [dragging, setDragging] = useState(false);

    const zoom = useSelector(selectZoom)

    const proxySetPosition = useCallback((e) => {
        const clicked = new Vector(e.movementX / zoom, e.movementY / zoom)

        setPosition(new Vector(position.x, position.y).add(clicked))
    }, [zoom, setPosition, position])

    return <>
        <div {...props}
            onMouseDown={e => {
                if (!is_active) return;
                if (e.buttons !== 1) return;
                e.preventDefault();
                e.stopPropagation();
                setDragging(true)
            }}
            onMouseUp={e => {
                if (!is_active) return;
                if (e.buttons !== 1) return;
                e.preventDefault();
                e.stopPropagation();
                setDragging(false)
            }}
            style={{
                ...style,
                position: 'absolute',
                left: `${Math.floor(position.x)}px`,
                top: `${Math.floor(position.y)}px`,
                transform: `translateX(-50%) translateY(-50%) rotate(${rotation}deg)`
            }} className={`dragable-handle ${is_active ? 'active' : ''} ${className}`}>
            {children}
        </div>
        {dragging && is_active && <MouseEventHandler
            killAllOtherEvents
            mouseMove={e => {
                e.preventDefault();
                e.stopPropagation();
                proxySetPosition(e)
            }}
            mouseUp={_ => setDragging(false)}/>}
    </>
}
