import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {
    doCreateTable, doFetchRoomTables, doSetEditingTable, doSetHoveringTable, doSetStopHoveringTable,
    doSetTableLocally,
    doUpdateTable,
    selectAllTables, selectEditingTableId, selectHoveringTableId
} from "../../slice/tableSlice";
import debounce from "lodash.debounce";
import SimpleButton from "../../component/simple-button/SimpleButton";
import InputField from "../../component/input-field/InputField";
import CenteredPopup from "../../component/centered-popup/CenteredPopup";
import './room-page.css'
import TableEditWindow from "../../component/table-edit-window/TableEditWindow";
import {
    doFetchRoom,
    doUpdateRoom,
    selectRoomById,
} from "../../slice/roomSlice";
import MeasuringTool from "../../component/measuring-tool/MeasuringTool";
import RoomTable from "../../component/room-table/RoomTable";
import {useKeyboardShortcut} from "../../UseKeyboardShortcut";
import Room from "../../component/room/Room";


export default function RoomPage() {
    const [creatingNewTable, setCreatingNewTable] = useState(false)
    const [newTableName, setNewTableName] = useState('');
    const [newTableMax, setNewTableMax] = useState(8);
    const [newTableType, setNewTableType] = useState('Square');
    const [newTableLength, setNewTableLength] = useState(2);
    const [newTableWidth, setNewTableWidth] = useState(0.8);

    // Display popup to make sure user understands which room they are changing.
    const [displayPopup, setDisplayPopup] = useState(true);

    // copied table
    const [placingTable, setPlacingTable] = useState(null);

    let {room_id} = useParams();

    const dispatch = useDispatch()
    const tables = useSelector(selectAllTables);
    const room = useSelector(selectRoomById(room_id))
    const hoveringTableId = useSelector(selectHoveringTableId)
    const editingTableId = useSelector(selectEditingTableId);

    useEffect(() => {
        dispatch(doFetchRoomTables(room_id))
        dispatch(doFetchRoom(room_id))
    }, [dispatch, room_id]);

    const updateTableBackend = useCallback(updated => {
        updated.forEach(table => {
            let dirty = {...table.dirty}
            const pos = dirty.position
            if (dirty.position) {
                dirty = {
                    ...dirty,
                    position: {x: Math.floor(pos.x), y: Math.floor(pos.y)}
                }
            }
            dispatch(doUpdateTable({
                table_id: table.id,
                values: dirty
            }))
        })
    }, [dispatch]);

    const debouncedUpdateTable = useMemo(() => debounce(updateTableBackend, 800), [updateTableBackend]);

    const setTable = useCallback((id, values) => {
        debouncedUpdateTable(tables.filter(t => !!t.dirty))
        dispatch(doSetTableLocally({table_id: id, values}))
    }, [debouncedUpdateTable, dispatch, tables]);

    const onCreateTable = useCallback(() => {
        dispatch(doCreateTable({
            room_id,
            name: newTableName,
            max_seating: newTableMax,
            length: newTableLength,
            width: newTableWidth,
            type: newTableType,
            position: {x: 100, y: 100}
        }))
        setNewTableName('');
        setNewTableMax(8);
    }, [dispatch, newTableName, newTableMax, room_id, newTableLength, newTableWidth, newTableType])

    const placeCopiedTable = useCallback(() => {
        console.log('Placing!')
        dispatch(doCreateTable(placingTable));
        setPlacingTable(null);
    }, [dispatch, placingTable]);

    useKeyboardShortcut({
        shortcutKeys: ['Enter'], keyUpCallback: placeCopiedTable
    })

    return (<div className='room-page'>
        <CenteredPopup showPopup={creatingNewTable} closePopup={setCreatingNewTable}>
            <h2>New table</h2>
            <InputField title='Name' value={newTableName} onChanged={setNewTableName}/>
            <InputField title='Max seating' value={newTableMax} onChanged={setNewTableMax}/>
            <InputField title='Type' options={['Square', 'Round']} onlySelectableOptions value={newTableType}
                        onChanged={setNewTableType}/>
            <InputField title='Length' value={newTableLength} onChanged={setNewTableLength}/>
            <InputField title='Width' value={newTableWidth} onChanged={setNewTableWidth}/>
            <SimpleButton value='Create' onClick={onCreateTable}/>
        </CenteredPopup>

        <div className='menu'>
            <Link to={-1}>Back to venue</Link>
            <h1>{room.name}</h1>
            <p>Scale (pixel pr meter): {room.pixel_pr_meter}</p>
            <SimpleButton value='Create new table' onClick={_ => setCreatingNewTable(true)}/>
            <div className='table-scroll'>
                <table>
                    <thead>
                    <tr>
                        <th>Navn</th>
                        <th>Type</th>
                        <th>Pladser</th>
                        <th>Max pladser</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tables.map(t => {
                        const hovering = editingTableId === t.id || hoveringTableId === t.id
                        return <tr onClick={_ => dispatch(doSetEditingTable(t.id))}
                                   className={`${hovering && 'hovering'}`} key={t.id}
                                   onMouseEnter={_ => dispatch(doSetHoveringTable(t.id))}
                                   onMouseLeave={_ => dispatch(doSetStopHoveringTable(t.id))}>
                            <td>{t.name}</td>
                            <td>{t.type}</td>
                            <td>{t.active_seats}</td>
                            <td>{t.max_seating}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </div>
        <TableEditWindow editingVenue={!!room.venue_id} editingEvent={!!room.event_id}/>
        <div className='room-wrapper'>
            <Room room_id={room_id}>
                {tables.map(t => <RoomTable key={t.id} table={t} copyTableForPlacement={t => {
                    setPlacingTable({...t, id: -1, name: parseInt(t.name) + 1})
                }} room={room} setTable={setTable}/>)}

                {placingTable && <RoomTable key={'new-table'}
                                            setTable={(_, {position}) => setPlacingTable(p => ({...p, position}))}
                                            table={placingTable}
                                            room={room}
                />}
            </Room>
            {placingTable && <div className='measuring-tool-input-area'>
                <InputField startWithFocus submit={placeCopiedTable} title='Name:' value={placingTable.name}
                            onChanged={v => setPlacingTable(p => ({...p, name: v}))}/>

                <SimpleButton value='submit' onClick={placeCopiedTable}/>
            </div>}
        </div>
        {displayPopup && <div className='display-which-room-type-wrapper'>
            {!!room.event_id &&
                <h2>You are changing an EVENT room. Changes made here only apply to this specific event.</h2>}

            {!!room.venue_id && <>
                <h2>You are changing a VENUE</h2>
                <h3>All changes made to this room only apply to future events.</h3>
                <h3>If you want to make a change to a specific event - go to that event and click 'edit event'</h3>
            </>}
            <SimpleButton value='I understand' onClick={_ => setDisplayPopup(false)}/>
        </div>}
    </div>)
}
