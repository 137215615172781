import React, {useCallback, useEffect, useMemo, useState} from 'react'
import './admin.css'
import {useNavigate, useParams} from "react-router-dom";
import {UserApi} from "../../api";
import AdminUserSection from "./user-section/AdminUserSection";
import AdminVenueSection from "./venue-section/AdminVenueSection";
import NewUser from "../../component/new-user/NewUser";
import {useDispatch, useSelector} from "react-redux";
import {
    doFetchAdminUsers,
    doFetchAdminVenues,
    selectAdminVenues
} from "../../slice/adminSlice";

export default function AdminPage() {
    const dispatch = useDispatch()

    const allVenues = useSelector(selectAdminVenues)

    const {section} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(doFetchAdminVenues())
        dispatch(doFetchAdminUsers())
    }, [dispatch]);

    useEffect(() => {
    }, [dispatch, section]);

    const doCreateUser = useCallback(values => {
        UserApi.create(values).then(_ => {
            window.alert('Created user.')
            dispatch(doFetchAdminUsers())
        })
    }, []);

    return (<div className='admin-page'>
        <div className='left-menu'>
            <li onClick={_ => navigate('/admin/Users')} className={(!section || section === 'Users') ? 'selected' : ''}>Users</li>
            <li onClick={_ => navigate('/admin/Venues')} className={section === 'Venues' ? 'selected' : ''}>Venues</li>
            <li onClick={_ => navigate('/admin/new-user')} className={section === 'new-user' ? 'selected' : ''}>New user</li>

            <div className='grower' />
            <li onClick={_ => navigate('/')}>Home</li>
        </div>
        <main>
            {(!section || section === 'Users') && <AdminUserSection
                allVenues={allVenues}
            />}

            {section === 'Venues' && allVenues && <AdminVenueSection
                allVenues={allVenues}
            />}
            {section === 'new-user' && <div>
                <NewUser title='Ny bruger' showRole submit={doCreateUser} />
            </div>
            }
        </main>
    </div>)
}
