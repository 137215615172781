import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import clear from '../../assets/clear-24px.svg'
import arrowDown from '../../assets/arr_down_white.svg'
import './multi-input-field.css'
import {useKeyboardShortcut} from "../../UseKeyboardShortcut";

export default function MultiInputField(
    {
        title,
        type,
        placeholder,
        onChanged,
        value,
        onBlur,
        startWithFocus,
        style,
        className,
        error,
        disabled,
        submit
    }) {
    // const [values, setValues] = useState([])
    // const [nextItem, setNextItem] = useState('')

    const [focused, setFocused] = useState(false);
    const inputRef = useRef()

    const values = useMemo(() => {
        const elements = value.split(',')
        if (value[value.length] === ',') {
            return elements
        }
        return elements.splice(0, elements.length - 1)
    }, [value]);

    const nextItem = useMemo(() => {
        if (value[value.length] === ',') {
            return ''
        }
        if (value.indexOf(',') === -1) {
            return value
        }
        return value.substring(value.lastIndexOf(',') + 1)
    }, [value]);

    const proxyOnChanged = useCallback(v => {
        if (v[0] === ',') {
            v = v.substring(1)
        }
        if (values.length === 0) {
            return onChanged(v)
        }
        onChanged(values.join(',') + ',' + v)
    }, [onChanged, values])

    const enterClicked = useCallback(e => {
        if (!nextItem) return;
        proxyOnChanged(nextItem + ',')
    }, [nextItem, proxyOnChanged]);


    useKeyboardShortcut({
        shortcutKeys: ['Enter'],
        keyUpCallback: enterClicked,
        validateEvent: _ => focused
    });

    useEffect(() => {
        if (startWithFocus && inputRef.current) {
            inputRef.current.focus()
        }
    }, [startWithFocus, inputRef]);

    return <div className={`multi-input-field ${className ? className : ''}`}
                style={style} onClick={e => e.stopPropagation()}>
        <div className={`input-title-wrapper ${disabled && 'disabled'}`}>
            {(title || error) &&
                <label className='title-label'>{title}{error && <label className='error'> - {error}</label>}</label>}
            <div className='input-field-wrapper' onClick={_ => inputRef.current.focus()}>
                {values.map(v => <label key={v}>{v}</label>)}
                <input type={type}
                       onFocus={_ => setFocused(true)}
                       onBlur={e => {
                           if (onBlur) onBlur()
                           setFocused(false)
                           enterClicked()
                       }}
                       className={''}
                       value={nextItem}
                       checked={nextItem}
                       ref={inputRef}
                       placeholder={placeholder}
                       disabled={disabled}
                       onChange={e => {
                           e.preventDefault();
                           e.stopPropagation();
                           proxyOnChanged(e.target.value)
                       }}
                       onKeyUp={e => {
                           if (e.code === 'Backspace') {
                               if (e.target.value === '') {
                                   e.preventDefault();
                                   e.stopPropagation();
                                   onChanged(value.substring(0, value.length-1))
                               }
                           }
                       }}
                />
            </div>
        </div>
    </div>
}
