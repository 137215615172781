import React from 'react'
import './loading-page.css'

export default function LoadingPage() {
    return (<div className={'loading-page'}>
        <div className='ground' />
        <div className='disc' />
        <h4>Loading CoasterClub</h4>
    </div>)
}
