import React, {useCallback, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    doSeatGuests,
    doSetEditingTable,
    doSetHoveringTable, doSetStopHoveringTable,
    selectHoveringTableId
} from "../../slice/tableSlice";
import Dragable from "../dragable-handle/Dragable";
import './seating-table.css'
import {doSetDraggingData, selectDraggingData} from "../../slice/seatingSlice";
import {selectCountByGuestIds} from "../../slice/guestSlice";

export default function SeatingTable({table, room, onClick}) {
    const dispatch = useDispatch()
    const hoveringTableId = useSelector(selectHoveringTableId)

    const [dragHovering, setDragHovering] = useState('');
    const [hoveringLabelOverride, setHoveringLabelOverride] = useState(null);

    const draggingData = useSelector(selectDraggingData);

    const tableCount = useSelector(selectCountByGuestIds(table.seats))

    const onDrop = useCallback(e => {
        e.preventDefault();
        const data = e.dataTransfer.getData('application/seating')
        const {guest_ids, count} = JSON.parse(data)
        const openSeats = table.active_seats - tableCount

        if (openSeats >= count) {
            dispatch(doSeatGuests({table_id: table.id, guest_ids}))
        }
        dispatch(doSetDraggingData(null))
    }, [dispatch, table, tableCount]);

    const onDragOver = useCallback(e => {
        e.preventDefault()
        const openSeats = table.active_seats - tableCount

        const {count} = draggingData

        dispatch(doSetHoveringTable(table.id))
        setHoveringLabelOverride(openSeats - count)
        if (openSeats < count) {
            e.dataTransfer.dropEffect = 'none'
            setDragHovering('none')
        } else {
            e.dataTransfer.dropEffect = 'link'
            setDragHovering('link')
        }
    }, [dispatch, draggingData, table, tableCount]);

    const onDragLeave = useCallback(table_id => {
        setDragHovering('')
        dispatch(doSetStopHoveringTable(table_id))
        setHoveringLabelOverride(null)
    }, [dispatch])

    const hovering = hoveringTableId === table.id
    let openSeats = table.active_seats - tableCount
    openSeats = hoveringLabelOverride ?? openSeats

    return (<Dragable
        onDrop={onDrop}
        onDragOver={onDragOver}

        // onMouseEnter={_ => dispatch(doSetHoveringTable(table.id))}
        onMouseLeave={_ => onDragLeave(table.id)}
        onDragLeave={_ => onDragLeave(table.id)}

        onClick={onClick ?? (_ => {})}

        key={table.id}
        className={`
            seating-table 
            ${table.type} 
            ${table.dirty && 'dirty'} 
            ${hovering ? `hovering-${dragHovering}` : ''} 
            ${table.id === -1 && 'being-placed'}
            ${openSeats === table.active_seats && 'none-seated'}
            ${openSeats < table.active_seats && openSeats > 0 && 'half-seated'}
            ${openSeats <= 0 && 'completely-seated'}
        `}

        position={table.position}
        rotation={table.rotation}
        style={{
            width: `${table.width * room.pixel_pr_meter}px`,
            height: `${table.length * room.pixel_pr_meter}px`,
            transform: `translateX(-50%) translateY(-50%) rotate(${table.rotation}deg)`,
        }}>
        <span className={`table-name`}
              onMouseDown={e => e.stopPropagation()}
              style={{
                  left: table.type === 'Square' ? `${table.width * room.pixel_pr_meter-2}px` : `${table.width * room.pixel_pr_meter * 0.5}px`,
        }}>
            {table.name}
        </span>
        <p className='seats-left-label'>
            {openSeats}
        </p>
    </Dragable>)
}
