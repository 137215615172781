import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {createRoomError, createRoomLoading, doCreateVenueRoom} from "../../../slice/venueSlice";
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";
import InputField from "../../../component/input-field/InputField";
import KFileDrop from "../../../component/k-file-drop/KFileDrop";
import SimpleButton from "../../../component/simple-button/SimpleButton";


export default function CreateRoom({venue_id, creatingNewRoom, setCreatingNewRoom}) {
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [width, setWidth] = useState('')
    const [height, setHeight] = useState('')
    const [floorplanImage, setFloorplanImage] = useState({})
    const [creatingRoomLocally, setCreatingRoomLocally] = useState(false);

    const createLoading = useSelector(createRoomLoading)
    const createError = useSelector(createRoomError)

    const submitCreateRoom = useCallback(() => {
        let args = {
            venue_id,
            name,
            ...floorplanImage,
            position_x: 0,
            position_y: 0
        }
        if (width) args.width = width
        if (height) args.width = width
        dispatch(doCreateVenueRoom(args))
        setCreatingRoomLocally(true)
    }, [dispatch, venue_id, name, width, height, floorplanImage]);

    const uploadedImage = useCallback((image_name, image_data) => {
        setFloorplanImage({image_name, image_data})
    }, []);

    useEffect(() => {
        console.log({creatingRoomLocally, createLoading, createError})
        if (!createLoading && creatingRoomLocally) {
            setCreatingRoomLocally(false);

            if (!createError) {
                setCreatingNewRoom(false);

                setName('')
                setWidth('')
                setHeight('')
                setFloorplanImage({})
            }
        }
    }, [creatingRoomLocally, createLoading, setCreatingNewRoom, createError]);

    return (<CenteredPopup showPopup={creatingNewRoom} closePopup={setCreatingNewRoom} className='room-popup'>
        <h1>Creating a new room</h1>

        <div className='horizontal'>
            <InputField value={name} title={'Name'} onChanged={setName}/>
        </div>
        <div className='horizontal'>
            <InputField value={width} title={'Width'} onChanged={setWidth}/>
            <InputField value={height} title={'Height'} onChanged={setHeight}/>
            <span>Kan indtastes senere.</span>
        </div>

        {floorplanImage.image_data &&
            <img src={floorplanImage.image_data} alt={`selected floor plan: ${floorplanImage.image_name}`}/>}
        <KFileDrop text={'Upload billede af salplanen her'}
                   displayFiles={false} isImages didSelectFile={uploadedImage}
                   accept={".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff"}/>

        <SimpleButton value='CREATE' big onClick={submitCreateRoom} loading={createLoading}/>
    </CenteredPopup>)
}
