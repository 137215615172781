import React from 'react'
import {Link, useParams} from "react-router-dom";
import './admin-header.css'


export default function AdminHeader(props) {
    const {event_id, venue_id} = useParams()
    return (<div className={'admin-header'}>
        {event_id && <Link to={`/venue/${venue_id}/event/${event_id}`}>Home</Link>}
        {event_id && <Link to={`/venue/${venue_id}/event/${event_id}/waiters`}>Waiter Export</Link>}
        {event_id && <Link to={`/venue/${venue_id}/event/${event_id}/dietary`}>Dietary</Link>}
        {event_id && <Link to={`/venue/${venue_id}/event/${event_id}/door-list`}>Door list</Link>}
        {event_id && venue_id === '2' && <Link to={`/venue/${venue_id}/event/${event_id}/sign-up`}>Sign-up</Link>}
    </div>)
}
