import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import BurgerMenu from "../../../component/burger-menu/BurgerMenu";
import ImportGuestList from "../../../component/import-guest-list/ImportGuestList";
import './client-header.css'


export default function ClientHeader({children}) {
    const dispatch = useDispatch()
    return (<div className={'client-header'}>
        {children}
    </div>)
}
