import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser, selectUserLoggedIn} from "../slice/userSlice";

export default function Logout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userLoggedIn = useSelector(selectUserLoggedIn)

    useEffect(() => {
        dispatch(logoutUser())
    }, [dispatch]);

    useEffect(() => {
        if (!userLoggedIn) navigate('/login')
    }, [navigate, userLoggedIn])

    return <div>Redirecting to login</div>
}
