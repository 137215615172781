import React, {useCallback, useEffect, useMemo, useState} from 'react'
import './signup-form-page.css'
import ClickToShow from "../../../../component/click-to-show/ClickToShow";
import InputField from "../../../../component/input-field/InputField";
import MultiInputField from "../../../../component/multi-input-field/MultiInputField";
import SimpleButton from "../../../../component/simple-button/SimpleButton";
import {invitationAdminAPI} from "../../../../api";
import {useParams} from "react-router-dom";
import MultipleChoice from "../../../../component/multiple-choice/MultipleChoice";
import cross from '../../../../assets/clear-24px.svg'
import LoadingAnimation from "../../../../component/loading-animation/LoadingAnimation";
import debounce from "lodash.debounce";


export default function SignupFormPage(props) {
    const {event_id} = useParams()

    const [originalQuestions, setOriginalQuestions] = useState([]);
    const [questions, setQuestions] = useState([]);

    // new question
    const [text, setText] = useState('');
    const [type, setType] = useState('');
    const [choices, setChoices] = useState('');
    const [creatingQuestion, setCreatingQuestion] = useState(false);

    // use callbacks
    const doSetQuestions = useCallback(qs => {
        setQuestions(qs)
        setOriginalQuestions(qs)
    }, []);

    const updateTableBackend = useCallback((id, key, value) => {
        invitationAdminAPI.update_question(event_id, id, {
            [key]: value
        }).then(doSetQuestions)
    }, [event_id, doSetQuestions]);

    const updateQuestionsLocally = useMemo(() => debounce(updateTableBackend, 800), [updateTableBackend]);

    const updateQuestion = useCallback((id, key, value) => {
        updateQuestionsLocally(id, key, value)
        setQuestions(prev => prev.map(q => q.id === id ? {...q, [key]: value} : q))
    }, [updateQuestionsLocally]);

    // effects
    useEffect(() => {
        if (!event_id) return;
        invitationAdminAPI.list_questions(event_id).then(doSetQuestions)
    }, [event_id, doSetQuestions]);

    return (<main className={'signup-form-page'}>
        <h1>Signup</h1>
        <ClickToShow buttonText='New question' className='new-question-form'>
            <h2>Adding question</h2>
            <InputField
                value={text}
                onChanged={setText}
                title='Question text:'/>
            <InputField
                value={type}
                onChanged={setType}
                title='Type:' options={['text', 'multiple-choice']} onlySelectableOptions/>
            {type === 'multiple-choice' && <MultiInputField
                value={choices}
                onChanged={setChoices}
                title='Choices'
            />}
            {creatingQuestion && <LoadingAnimation/>}
            <SimpleButton value='add question' onClick={_ => {
                invitationAdminAPI.create_question(event_id, text, type, choices).then(doSetQuestions)
                setText('')
                setType('')
                setChoices('')
            }}/>
        </ClickToShow>

        {questions.map((question, q_idx) => <div key={question.id} className='question'>
            <img
                onClick={_ => invitationAdminAPI.delete_question(event_id, question.id).then(doSetQuestions)}
                src={cross}
                alt='delete-question'
                className='delete-question'/>
            <h2>Question {q_idx + 1}:</h2>
            <input className='question-text'
                   value={question.question_text}
                   onChange={e => {
                       updateQuestion(question.id, 'question_text', e.target.value)
                   }}/>
            <InputField
                className='question-type'
                value={question.question_type}
                options={['text', 'multiple-choice']}
                onlySelectableOptions
                onChanged={v => {
                    updateQuestion(question.id, 'question_type', v)
                }}
            />
            {question.question_type === 'multiple-choice' && <MultipleChoice onChoicesChanged={newChoices => {
                updateQuestion(question.id, 'choices', newChoices)
            }} choices={question.choices} editable/>}
        </div>)}
    </main>)
}
