import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import './seating-header.css'
import TableSearchBar from "../../page/seating/table-search-bar/TableSearchBar";
import SpecialSearchIcon from "../special-search-icon/SpecialSearchIcon";
import BurgerMenu from "../burger-menu/BurgerMenu";
import ImportGuestList from "../import-guest-list/ImportGuestList";
import {useNavigate, useParams} from "react-router-dom";
import SimpleButton from "../simple-button/SimpleButton";


export default function SeatingHeader(props) {
    const {event_id, venue_id} = useParams()
    const navigate = useNavigate()
    return (<div className={'seating-header'}>
        {window.location.pathname.indexOf('/seating') !== -1 && <TableSearchBar/>}
        <div className='grower' />
        {/*<SpecialSearchIcon icon={'🍴'} text={'Show special diet guests'} />*/}
        {/*<SpecialSearchIcon icon={'♿'} text={'Show wheelchair users'} />*/}
        <ImportGuestList/>

        <SimpleButton value='Home' style={{background: '#44657c', marginLeft: '8px'}} onClick={_ => {
            if (venue_id && event_id) {
                navigate(`/venue/${venue_id}/event/${event_id}`)
            } else {
                navigate('/seating')
            }
        }} />
    </div>)
}
