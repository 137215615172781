import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {selectRoomById, setExternalNaturalImageSize} from "../../slice/roomSlice";
import {useKeyboardShortcut} from "../../UseKeyboardShortcut";
import './room.css'
import Navigatable from "../navigatable/Navigatable";


export default function Room({room_id, children}) {
    const dispatch = useDispatch()
    const room = useSelector(selectRoomById(room_id))
    const [hideFloorplan, setHideFloorplan] = useState(false);

    useKeyboardShortcut({
        shortcutKeys: ['b'], keyUpCallback: ev => {
            setHideFloorplan(p => !p)
        },
        validateEvent: e => e.ctrlKey
    })

    return (<Navigatable
        undragableClasses={['fancy-border-outer', 'fancy-border-inner', 'power-table', 'ptd', 'seating-label-wrapper', 'power-table-row']}>
        <div className='room'>
            <img
                onLoad={(img) => {
                    dispatch(setExternalNaturalImageSize({
                        x: img.target.naturalWidth,
                        y: img.target.naturalHeight,
                    }))
                }}
                alt='floor plan'
                src={room.signed}
                style={{pointerEvents: 'none', userSelect: 'none', opacity: hideFloorplan ? '0' : '1'}}
            />
            {children}
        </div>
    </Navigatable>)
}
