import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import InputField from "../input-field/InputField";
import SimpleButton from "../simple-button/SimpleButton";
import MovableWindow from "../movable-window/MovableWindow";
import {
    doDeleteTable,
    doSetEditingTable,
    doSetTableLocally,
    doUpdateTable,
    selectEditingTable, selectEditingTableId
} from "../../slice/tableSlice";
import debounce from "lodash.debounce";
import './table-edit-window.css';
import {useKeyboardShortcut} from "../../UseKeyboardShortcut";


export default function TableEditWindow({editingVenue, editingEvent}) {
    const [changed, setChanged] = useState({});

    const dispatch = useDispatch()

    const editingTableId = useSelector(selectEditingTableId);
    const editingTable = useSelector(selectEditingTable);

    useEffect(() => {
        setChanged({})
    }, [editingTableId]);

    const updateTableBackend = useCallback((editingTableId, changed) => {
        dispatch(doUpdateTable({
            table_id: editingTableId,
            values: changed
        }))
    }, [dispatch]);

    const debouncedUpdateTable = useMemo(() => debounce(updateTableBackend, 800), [updateTableBackend]);

    useEffect(() => {
        if (Object.keys(changed).length === 0 || !editingTableId) return;

        debouncedUpdateTable(editingTableId, changed)
    }, [editingTableId, changed])

    const setTable = useCallback((id, values) => {
        setChanged(p => ({...p, ...values}))
        dispatch(doSetTableLocally({table_id: id, values}))
    }, [debouncedUpdateTable, dispatch]);

    const closeEdit = useCallback(() => {
        dispatch(doSetEditingTable(null))
    }, [dispatch]);

    if (!editingTable || Object.keys(editingTable).length === 0) return;

    return (<div className='table-edit-window' title={editingTable.name}
                           onClose={closeEdit}>
        <p>id: {editingTable.id}</p>
        <p>Type: {editingTable.max_seating}-{editingTable.type}</p>
        <InputField
            title='Table name'
            value={editingTable.name}
            onChanged={n => setTable(editingTable.id, {name: n})}
        />
        {editingEvent && <InputField
            title='Max seated for this event'
            value={editingTable.active_seats}
            onChanged={n => setTable(editingTable.id, {active_seats: n})}
        />}
        {editingVenue && <InputField
            title='Max table seats'
            value={editingTable.max_seating}
            onChanged={n => setTable(editingTable.id, {max_seating: n})}
        />}
        <InputField
            title='Width'
            value={editingTable.width}
            onChanged={n => setTable(editingTable.id, {width: n})}
        />
        <InputField
            title='Length'
            value={editingTable.length}
            onChanged={n => setTable(editingTable.id, {length: n})}
        />
        <InputField
            title='Type'
            value={editingTable.type}
            onChanged={n => setTable(editingTable.id, {type: n})}
            options={['Round', 'Square', 'Sofa']}
            onlySelectableOptions
        />

        <p className='rotation-handle-label'>Rotation</p>
        <label>{editingTable.rotation}</label>
        <input className='rotation-handle' type='range' min={-180} max={180} value={editingTable.rotation} onChange={v => setTable(editingTable.id, {rotation: v.target.value})} />
        <br />
        <SimpleButton value='Delete table' className='red' big onClick={_ => dispatch(doDeleteTable(editingTable.id))}/>

        <SimpleButton value='OK' onClick={closeEdit} big />
    </div>)
}
