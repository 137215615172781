import React, {useEffect} from 'react'
import './home.css'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import BlogLayout from "../../component/blog-layout/BlogLayout";
import {doGetUser, doGetUserEvents, selectUser} from "../../slice/userSlice";
import GuestHome from "./guest-home/GuestHome";
import LoadingPage from "../../component/loading-page/LoadingPage";

export default function HomePage() {
    const user = useSelector(selectUser)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(doGetUser())
        dispatch(doGetUserEvents())
    }, [dispatch]);

    const navigate = useNavigate()

    useEffect(() => {
        if (Object.keys(user).length === 0) return;
        if (['developer', 'venue-owner'].indexOf(user.role) !== -1) {
            navigate(`/venue`)
        }
    }, [user, navigate])

    return (<>
        {!Object.keys(user).length && <LoadingPage/>}
        <GuestHome/>
    </>)
}
