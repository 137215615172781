import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import './power-table.css'
import {doSetDraggingData} from "../../slice/seatingSlice";
import {doSeatGuests, doUnseatGuests, selectTableById} from "../../slice/tableSlice";
import {useKeyboardShortcut} from "../../UseKeyboardShortcut";
import {
    doDeleteGuest,
    doSetSelectedGuests,
    fetchUpdatedEventGuests,
    selectCountByGuestIds, selectSelectedGuestIds, selectSelectedGuests
} from "../../slice/guestSlice";
import InputField from "../input-field/InputField";
import MultiInputField from "../multi-input-field/MultiInputField";
import SimpleButton from "../simple-button/SimpleButton";
import HoverButton from "../hover-button/HoverButton";
import edit from '../../assets/edit-white.svg'
import deleteIcon from '../../assets/bin.png'
import {GuestAPI} from "../../api";

export default function PowerTable({
                                       style,
                                       className,
                                       headers,
                                       rows,
                                       rowRefs,
                                       disableDrag = false,
                                       targetTableOnDrop,
                                       simpleView = false,
                                       editable = false,
                                   }) {
    const [sortByColumn, setSortByColumn] = useState('')
    const [ascDesc, setAscDesc] = useState(1)

    const [changes, setChanges] = useState({});

    const [editing, setEditing] = useState(false);

    const targetTable = useSelector(selectTableById(targetTableOnDrop))

    const dispatch = useDispatch()

    const componentSeatingId = useMemo(() => {
        return Math.random()
    }, []);

    const selectedGuests = useSelector(selectSelectedGuestIds(componentSeatingId))
    const selectedAmount = useSelector(selectCountByGuestIds(selectedGuests))

    useKeyboardShortcut({
        shortcutKeys: ['Escape'], keyUpCallback: () => {
            dispatch(doSetSelectedGuests({component_id: componentSeatingId, guest_ids: []}))
        }
    })

    function clickedSort(headerName) {
        if (headerName === '🍴') {
            headerName = 'dietary'
        }
        if (headerName === '♿') {
            headerName = 'wheelchairs'
        }
        if (headerName === sortByColumn) {
            if (ascDesc === -1) {
                setAscDesc(1);
                setSortByColumn('')
            } else {
                setAscDesc(-1);
            }
        } else {
            setSortByColumn(headerName)
            setAscDesc(1)
        }
    }

    const sort = useCallback((a, b) => {
        if (a[sortByColumn] === b[sortByColumn]) return 0;

        if (typeof (a[sortByColumn]) === 'string' || typeof (b[sortByColumn]) === 'string') {
            if (a[sortByColumn] === null) return -1 * ascDesc;
            if (b[sortByColumn] === null) return ascDesc;

            return a[sortByColumn].localeCompare(b[sortByColumn]) * ascDesc
        } else {
            if (a[sortByColumn] > b[sortByColumn]) {
                return -1 * ascDesc
            }
            if (a[sortByColumn] < b[sortByColumn]) {
                return ascDesc
            }
        }
        return 0
    }, [sortByColumn, ascDesc]);

    const sortedRows = useMemo(() => {
        if (sortByColumn === '') {
            return [...rows]
        } else {
            return [...rows].sort(sort)
        }
    }, [rows, sortByColumn, sort])

    const onDragStart = useCallback((ev, elem) => {
        let count = selectedAmount;
        let newSelectedGuests = [...selectedGuests]
        if (newSelectedGuests.indexOf(elem.id) === -1) {
            newSelectedGuests = [elem.id]
            const r = rows.find(r => r.id === elem.id)
            count = r.count
        }

        const dragData = {
            guest_ids: newSelectedGuests,
            count: count,
            timestamp: ev.timeStamp
        }

        ev.dataTransfer.setData("application/seating", JSON.stringify(dragData));
        ev.dataTransfer.dropEffect = 'link';

        dispatch(doSetDraggingData(dragData))

        dispatch(doSetSelectedGuests({component_id: componentSeatingId, guest_ids: newSelectedGuests}))

    }, [dispatch, selectedGuests, selectedAmount, rows]);

    const onDrop = useCallback(e => {
        e.preventDefault();
        const data = e.dataTransfer.getData('application/seating')
        const {guest_ids, count} = JSON.parse(data)

        if (targetTable) {
            const openSeats = targetTable.active_seats - targetTable.seats.length

            if (openSeats >= count) {
                dispatch(doSeatGuests({table_id: targetTable.id, guest_ids}))
            }
        } else {
            dispatch(doUnseatGuests({guest_ids}))
        }
    }, [dispatch, targetTable]);

    const onDragOver = useCallback((e) => {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'link'
    }, []);

    const elementClicked = useCallback((event, elem) => {
        if (event.shiftKey) {
            if (selectedGuests.indexOf(elem.id) === -1) {
                dispatch(doSetSelectedGuests({
                    component_id: componentSeatingId,
                    guest_ids: [...selectedGuests, elem.id]
                }))
            } else {
                dispatch(doSetSelectedGuests({
                    component_id: componentSeatingId,
                    guest_ids: selectedGuests.filter(p => p !== elem.id)
                }))
            }
        } else {
            if (selectedGuests.length === 1 && selectedGuests[0] === elem.id) {
                dispatch(doSetSelectedGuests({
                    component_id: componentSeatingId,
                    guest_ids: []
                }))
            } else {
                dispatch(doSetSelectedGuests({
                    component_id: componentSeatingId,
                    guest_ids: [elem.id]
                }))
            }
        }
    }, [dispatch, componentSeatingId, selectedGuests]);

    const updateGuestValue = useCallback((guest_id, key, new_value) => {
        setChanges(prev => ({...prev, [guest_id]: {...prev[guest_id], [key]: new_value}}))
    }, []);

    const displayHeaders = useMemo(() => {
        if (simpleView) return ['name', 'company', '🍴', '♿']
        return [...headers]
    }, [headers, simpleView])

    return (<div style={{...style}} className={`power-table ${className} ${editing && 'editing'}`}>
        <table draggable={false} onDrop={onDrop} onDragOver={onDragOver}>
            {<thead>
            <tr>
                {!simpleView && <th></th>}
                {displayHeaders.map(header => {
                    let translatedHeader = header
                    if (header === '🍴') translatedHeader = 'dietary'
                    if (header === '♿') translatedHeader = 'wheelchairs'

                    return <th style={{zIndex: 1}}
                               key={translatedHeader}
                               onClick={() => clickedSort(translatedHeader)}>
                        <label>{header}</label>
                        <svg
                            className={`arrow ${(sortByColumn === translatedHeader) ? 'focus' : ''} ${ascDesc === -1 ? "asc" : "desc"}`}
                            version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 330 330">
                            <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                        c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                        s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.394z"/>
                        </svg>
                    </th>
                })}
            </tr>
            </thead>}
            <tbody>
            {sortedRows.map(guest => {
                const selected = !editing && selectedGuests && selectedGuests.indexOf(guest.id) !== -1 ? 'selected' : ''
                return <tr
                    className={`power-table-row ${selected}`}
                    draggable={!disableDrag && !editing}
                    onDragStart={ev => onDragStart(ev, guest)}
                    onClick={ev => elementClicked(ev, guest)}
                    ref={el => rowRefs ? rowRefs.current[guest.id] = el : []} key={guest.id}>
                    {!simpleView && <td className='delete-icon-td'>
                        <img
                            onClick={_ => {
                                if (window.confirm(`Delete guest with name: ${guest.name}?`)) {
                                    dispatch(doDeleteGuest(guest.id))
                                }
                            }}
                            className='delete-icon'
                            src={deleteIcon}
                            key='delete'
                            alt={`delete guest with name: ${guest.name}`}/>
                    </td>}
                    {simpleView && <>
                        <td className='ptd'>
                            {guest.name}
                            {guest.count > 1 && <label className='count-icon'>+{guest.count-1}</label>}
                        </td>
                        <td className='ptd'>{guest.company}</td>

                        <td className='ptd'>
                            {guest.dietary && guest.dietary !== '0' && <label title={guest.dietary} className='dietary-label'>🍴</label>}
                        </td>

                        <td className='ptd'>
                            {guest.wheelchairs && <label title={guest.wheelchairs} className='wheelchair-label'>♿</label>}
                        </td>
                    </>}
                    {!simpleView && displayHeaders.map((header, idx) => {
                        if (!editing)
                            return <td className='ptd' key={idx}>{guest[header]}</td>

                        const changed = changes[guest.id]
                        let value = '';
                        if (changed) {
                            value = changes[guest.id][header] ?? (guest[header] ?? '')
                        } else {
                            value = guest[header] ?? ''
                        }

                        if (header !== 'wheelchairs') {
                            return <td className='ptd' key={idx}><InputField value={value} onChanged={v => {
                                updateGuestValue(guest.id, header, v)
                            }}/></td>
                        }

                        if (guest['count'] === 1) {
                            return <td className='ptd' key={idx}>
                                <input
                                    className='checkbox' type='checkbox'
                                    onChange={e => {
                                        const v = e.target.checked
                                        if (v) {
                                            updateGuestValue(guest.id, header, guest.name)
                                        } else {
                                            updateGuestValue(guest.id, header, '')
                                        }
                                    }}
                                    checked={!!value}/>
                            </td>
                        }

                        return <td className='ptd' key={idx}><MultiInputField
                            value={value}
                            onChanged={v => updateGuestValue(guest.id, header, v)}/>
                        </td>
                    })}
                </tr>
            })}
            </tbody>
        </table>
        {editable && !editing && <HoverButton icon={edit} onClick={_ => setEditing(true)}/>}
        {editing && <SimpleButton className='save-button' big value='Save' onClick={_ => {
            if (Object.keys(changes).length === 0) {
                setEditing(false)
                return;
            }

            GuestAPI.batchUpdateGuests(changes).then(_ => {
                dispatch(fetchUpdatedEventGuests(Object.keys(changes)))
                setChanges({})
            })
        }}/>}
    </div>)
}
