import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import './guest-home.css'
import {useNavigate} from "react-router-dom";
import {selectSelectedEvent, selectUserEventsLoading} from "../../../slice/userSlice";
import {doFetchEventGuests} from "../../../slice/guestSlice";
import LoadingPage from "../../../component/loading-page/LoadingPage";
import SimpleButton from "../../../component/simple-button/SimpleButton";

export default function GuestHome() {
    const dispatch = useDispatch()

    const eventLoading = useSelector(selectUserEventsLoading)
    const selectedEvent = useSelector(selectSelectedEvent);

    const navigate = useNavigate()

    useEffect(() => {
        if (!selectedEvent) return;
        dispatch(doFetchEventGuests(selectedEvent.id))
    }, [dispatch, selectedEvent]);

    if (eventLoading || !selectedEvent) {
        return (<div className='guest-home'>
            <LoadingPage/>
        </div>)
    }

    return (<div className='guest-home'>
        <h1>{selectedEvent.title}</h1>
        <pre className='note-to-client'>{selectedEvent.note_to_client}</pre>

        <SimpleButton value='Go to Manage Guests' onClick={_ => navigate('/guest')} />
        <hr/>

        <div className='grower'/>
        <div className='contact-wrapper'>
            <div className='vertical'>
                <h3>{selectedEvent.contact_name}</h3>
                <h3>{selectedEvent.contact_email}</h3>
                <h3>{selectedEvent.contact_phone}</h3>
            </div>
        </div>
    </div>)
}
