import React from "react"
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import LoginPage from "./page/login/LoginPage";
import HomePage from "./page/home/HomePage";
import RequireAuth from "./auth/RequireAuth";
import SeatingPage from "./page/seating/SeatingPage";
import AdminPage from "./page/admin/AdminPage";
import VenuePage from "./page/venue/VenuePage";
import RoomPage from "./page/room/RoomPage";
import EventPage from "./page/event/EventPage";
import Logout from "./component/Logout";
import SignupPage from "./page/signup-page/SignupPage";
import EventSignupAdminPage from "./page/event/event-signup-admin/EventSignupAdminPage";
import WaiterList from "./page/event/WaiterList";
import AdminPageWrapper from "./page/admin-page-wrapper/AdminPageWrapper";
import DoorList from "./page/event/DoorList";
import DietaryOverview from "./page/event/dietary-overview/DietaryOverview";
import VenueClientMenuWrapper from "./page/venue-client/venue-client-menu-wrapper/VenueClientMenuWrapper";
import GuestManagementPage from "./page/venue-client/guest-management-page/GuestManagementPage";
import VenueClientSeatingPage from "./page/venue-client/venue-client-seating-page/VenueClientSeatingPage";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/login' element={<LoginPage/>}/>
                <Route exact path='/logout' element={<Logout/>}/>

                <Route exact path='/admin' element={<RequireAuth>
                    <AdminPage/>
                </RequireAuth>}/>

                <Route path="/venue" element={<RequireAuth><AdminPageWrapper/></RequireAuth>}>
                    <Route path=':venue_id/event/:event_id' element={<EventPage/>}/>

                    <Route path=':venue_id/event/:event_id/dietary' element={<DietaryOverview/>}/>
                    <Route path=':venue_id/event/:event_id/door-list' element={<DoorList/>}/>
                    <Route path=':venue_id/event/:event_id/sign-up' element={<EventSignupAdminPage/>}/>

                    <Route path=':venue_id' element={<VenuePage/>}/>
                </Route>

                <Route exact path='/venue/:venue_id/event/:event_id/waiters' element={<RequireAuth>
                    <WaiterList/>
                </RequireAuth>}/>

                <Route exact path='/admin/:section' element={<RequireAuth>
                    <AdminPage/>
                </RequireAuth>}/>

                <Route exact path='/event/:event_id' element={<RequireAuth>
                    <EventPage/>
                </RequireAuth>}/>

                <Route exact path='/event/:event_id/seating' element={<RequireAuth>
                    <SeatingPage/>
                </RequireAuth>}/>
                <Route exact path='/venue/:venue_id/event/:event_id/seating' element={<RequireAuth>
                    <SeatingPage/>
                </RequireAuth>}/>

                <Route exact path='/room/:room_id' element={<RequireAuth>
                    <RoomPage/>
                </RequireAuth>}/>

                <Route exact path='/invitation/:token' element={
                    <SignupPage/>
                }/>

                <Route path='' element={<RequireAuth><VenueClientMenuWrapper/></RequireAuth>}>
                    <Route path='' element={<HomePage />} />
                    <Route path='/guest' element={<GuestManagementPage />} />
                    <Route path='/seating' element={<VenueClientSeatingPage />} />
                    <Route path='/export' element={<DoorList />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
