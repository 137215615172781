import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RoomAPI} from "../api";
import {Vector} from "vector2d";

const initialState = {
    rooms: {},
    loading: false,
    zoom: 1,
    naturalImageSize: {x: 1000, y: 1000},
    clientImageSize: {x: 1000, y: 1000},
}

export const doFetchRoom = createAsyncThunk('room/fetch', async (room_id) => {
    return await RoomAPI.get_room_by_id(room_id)
})

export const doFetchAllRooms = createAsyncThunk('room/doFetchAllRooms', async () => {
    return await RoomAPI.list_all_rooms()
})

export const doUpdateRoom = createAsyncThunk('room/update', async ({room_id, values}) => {
    return await RoomAPI.update_room(room_id, values)
})

export const doCreateRoom = createAsyncThunk('room/create', async ({venue_id, name, width, height}) => {
    return await RoomAPI.create(
        venue_id,
        name,
        0,
        0,
        width,
        height,
    )
})

export const doDeleteRoom = createAsyncThunk('room/delete', async (room_id) => {
    return await RoomAPI.delete_room(room_id)
})

export const doSetZoom = createAsyncThunk('room/setZoom', async (zoom) => {
    return new Promise(res => res(zoom))
})
export const setExternalNaturalImageSize = createAsyncThunk('room/setNaturalImageSize', async (size) => {
    return size
})
export const setExternalClientImageSize = createAsyncThunk('room/setClientImageSize', async (size) => {
    return size
})


const roomSlice = createSlice({
    name: 'room',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(doFetchRoom.fulfilled, (state, action) => {
                const fetchedRoom = action.payload
                state.rooms[fetchedRoom.id] = fetchedRoom
            })

            .addCase(doFetchAllRooms.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchAllRooms.fulfilled, (state, action) => {
                action.payload.forEach(room => {
                    state.rooms[room.id] = room
                })
                state.loading = false
            })

            .addCase(doUpdateRoom.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doUpdateRoom.fulfilled, (state, action) => {
                const updated = action.payload
                state.rooms[updated.id] = updated
                state.loading = false
            })

            .addCase(doCreateRoom.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doCreateRoom.fulfilled, (state, action) => {
                const newRoom = action.payload
                state.rooms[newRoom.id] = newRoom
                state.loading = false
            })

            .addCase(doDeleteRoom.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doDeleteRoom.fulfilled, (state, action) => {
                const deleted_id = action.payload
                delete state.rooms[deleted_id]
                state.loading = false
            })

            .addCase(doSetZoom.fulfilled, (state, action) => {
                state.zoom = action.payload
            })
            .addCase(setExternalNaturalImageSize.fulfilled, (state, action) => {
                state.naturalImageSize = action.payload
            })
            .addCase(setExternalClientImageSize.fulfilled, (state, action) => {
                state.clientImageSize = action.payload
            })
    }
})

export default roomSlice.reducer

export const selectRoomById = id => state => {
    return state.room.rooms[id] || {}
}

export const selectRoomLoading = state => {
    return state.room.loading
}

export const selectZoom = state => {
    return state.room.zoom
}
export const selectNaturalImageSize = state => {
    const v = state.room.naturalImageSize
    return new Vector(v.x, v.y)
}
export const selectClientImageSize = state => {
    const v = state.room.clientImageSize
    return new Vector(v.x, v.y)
}
