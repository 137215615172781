import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useParams} from "react-router-dom";
import {EventApi} from "../../../api";
import AdminHeader from "../../admin-page-wrapper/admin-header/AdminHeader";
import './dietary-overview.css'
import SimpleButton from "../../../component/simple-button/SimpleButton";

export default function DietaryOverview() {
    const [data, setData] = useState(null);
    const {event_id} = useParams()

    useEffect(() => {
        EventApi.list_dietary_overview(event_id).then(setData)
    }, [event_id]);

    const copyable = useMemo(() => {
        if (!data) return ''

        const rows = data.map(g => {
            return [g.waiter, g.table, g.name, g.dietary, g.wheelchairs].join('\t')
        })

        return ['Tjener\tBord\tGæst navn\tDietary\tKørestol', ...rows].join('\n')
    }, [data]);

    return <div className='dietary-overview'>
        <AdminHeader />
        <h1>Dietære preferencer og allergier</h1>
        <SimpleButton value='copy' onClick={() => {navigator.clipboard.writeText(copyable)}} />
        <table>
            <thead><tr>
                <th>Tjener</th>
                <th>Bord</th>
                <th>Gæst navn</th>
                <th>Dietary</th>
                <th>Kørestol</th>
            </tr></thead>
            <tbody>
            {data && data.map(d => <tr key={d.guest_id}>
                <td>{d.waiter}</td>
                <td>{d.table}</td>
                <td>{d.name}</td>
                <td>{d.dietary}</td>
                <td>{d.wheelchairs}</td>
            </tr>)}
            </tbody>
        </table>
    </div>
}
