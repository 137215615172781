import React, {useCallback, useEffect, useState} from 'react'
import InputField from "../../../component/input-field/InputField";
import {adminAPI} from "../../../api";
import {useSelector} from "react-redux";
import {selectAdminUsers} from "../../../slice/adminSlice";


export default function AdminVenueSection({allVenues}) {
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [venueUsers, setVenueUsers] = useState(null);

    const users = useSelector(selectAdminUsers)

    useEffect(() => {
        if (!selectedVenue) return;
        adminAPI.list_venue_users(selectedVenue.id).then(setVenueUsers)
    }, [selectedVenue]);

    const onSelectedVenue = useCallback(name => {
        if (name === '') return;

        const matching = allVenues.filter(u => u.name === name)
        if (matching.length === 0) {
            console.log('Found no matching venues o.o', {name, venues: allVenues.map(u => u.name)})
            return;
        }
        if (matching.length > 1) {
            console.log('Matched several venues from name', {name, matching})
            window.alert('More than one user matched that name?')
            return;
        }
        setSelectedVenue(matching[0])
    }, [allVenues]);

    const addOrRemoveUser = useCallback((user_id, venue_id, do_add) => {
        if (do_add) {
            adminAPI.add_user_to_venue(user_id, venue_id).then(_ => {
                setVenueUsers(prev => ([...prev, users.find(u => u.id === user_id)]))
            })
        } else {
            adminAPI.remove_user_from_venue(user_id, venue_id).then(_ => {
                setVenueUsers(prev => prev.filter(p => p.id !== user_id))
            })
        }
    }, [users]);

    return (<div className='search-area'>
        <InputField onlySelectableOptions
                    onChanged={onSelectedVenue}
                    options={allVenues.map(u => u.name)}
                    startWithFocus submit={console.log}/>
        <hr/>
        {selectedVenue && <div className='venue'>
            <h2>{selectedVenue.name}</h2>

            {users && venueUsers && <table>
                <tbody>
                {users.map(user => <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.role}</td>
                    <td><InputField type='checkbox'
                                    value={venueUsers.some(v => v.id === user.id)}
                                    onChanged={value => addOrRemoveUser(user.id, selectedVenue.id, value)}
                    /></td>
                </tr>)}
                </tbody>
            </table>}
        </div>}
    </div>)
}
