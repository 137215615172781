import React, {useCallback, useEffect, useState} from 'react'
import './login.css'
import InputField from "../../component/input-field/InputField";
import SimpleButton from "../../component/simple-button/SimpleButton";
import {useDispatch, useSelector} from "react-redux";
import {
    loginUser,
    selectLoginLoading, selectUser,
    selectUserError,
    selectUserLoading,
    selectUserLoggedIn
} from "../../slice/userSlice";
import loginBackground from '../../assets/CoasterLogo.png'
import {useNavigate} from 'react-router-dom';
import LoadingAnimation from "../../component/loading-animation/LoadingAnimation";

export default function LoginPage() {
    const dispatch = useDispatch()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()

    const isLoggedIn = useSelector(selectUserLoggedIn)
    const loading = useSelector(selectUserLoading)
    const loginError = useSelector(selectUserError)
    const loginLoading = useSelector(selectLoginLoading)
    const user = useSelector(selectUser)

    useEffect(() => {
        if (!isLoggedIn || !user || Object.keys(user).length === 0) return;

        const redir_path = localStorage.getItem('redirAfterLogin')
        if (redir_path && redir_path !== '/login') {
            localStorage.removeItem('redirAfterLogin')
            navigate(redir_path)
        } else {
            if (['developer', 'venue-owner'].indexOf(user.role) !== -1) {
                navigate(`/venue`)
            } else {
                navigate('/')
            }
        }
    }, [navigate, isLoggedIn, user]);

    const submit = useCallback(() => {
        dispatch(loginUser({email, password}))
    }, [dispatch, email, password])

    return (<div className='login-page'>
        <div className='left-menu'>
            <img src={loginBackground} alt='background'/>
        </div>
        <div className='right-menu'>
            <div className='wrapper'>
                <InputField
                    placeholder='Enter email'
                    value={email}
                    onChanged={setEmail}
                    displayClearIcon
                    submit={submit}
                    error={loginError && loginError.message}
                />
                <InputField
                    placeholder='Enter password'
                    value={password}
                    onChanged={setPassword}
                    type='password'
                    displayClearIcon
                    submit={submit}
                />
                {loginLoading && <LoadingAnimation/>}
                <SimpleButton big disabled={loginLoading} value='LOGIN' onClick={submit} loading={loading}/>
            </div>
        </div>
    </div>)
}
