import React, {useCallback, useEffect, useMemo, useState} from 'react'
import './navigatable.css'
import {renderer} from '../renderer/renderer';
import {useDispatch, useSelector} from "react-redux";
import {selectDraggingData} from "../../slice/seatingSlice";

export default function Navigatable({dragableClasses = [], undragableClasses = [], children}) {
    const dispatch = useDispatch();

    const [container, setContainer] = useState();
    const [appliedStartOffset, setAppliedStartOffset] = useState(false);

    const draggingData = useSelector(selectDraggingData)

    const instance = useMemo(() => {
        if (!container) return null;
        return renderer({scaleSensitivity: 1000, minScale: .6, maxScale: 10, element: container.children[0]});
    }, [container]);

    const mouseMove = useCallback(event => {
        if (dragableClasses.length > 0 && dragableClasses.indexOf(event.target.className) === -1) return;
        if (undragableClasses.length > 0 && undragableClasses.indexOf(event.target.className) !== -1) return;

        const isMoving = event.buttons === 4 || (event.buttons === 1 && event.ctrlKey) || (event.buttons === 1)

        if (!isMoving) return;
        if (!instance) return;

        if (draggingData) {
            const diff = event.timeStamp - draggingData.timestamp
            if (diff < 100) return;
        }

        event.preventDefault();
        event.stopPropagation();

        instance.panBy({
            originX: event.movementX,
            originY: event.movementY
        });
    }, [dragableClasses, undragableClasses, instance, draggingData]);

    const onWheel = useCallback((event) => {
        event.stopPropagation();
        if (!instance) return;

        instance.zoom({
            deltaScale: -event.deltaY,
            x: event.pageX,
            y: event.pageY,
            dispatch
        });
    }, [instance, dispatch]);

    useEffect(() => {
        if (!instance || appliedStartOffset) return;
        setAppliedStartOffset(true);

        instance.zoom({
            deltaScale: -200,
            x: 1700,
            y: 1500,
            dispatch
        });
    }, [instance, container]);

    return (<div className='navigatable' ref={setContainer} onMouseMove={mouseMove} onWheel={onWheel}>
        {children}
    </div>)
}
