import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RoomAPI, VenueAPI} from "../api";

const initialState = {
    venues: [],
    rooms: {},
    loading: false,
    venueCreated: false,
    creatingRoomLoading: false,
    creatingRoomError: false,
}

export const startCreateNewVenue = createAsyncThunk('venue/creatingNew', async () => {
    return
})

export const doCreateVenueRoom = createAsyncThunk('venue/createRoom', async (args) => {
    console.log('Creating roomsssszz:', args)
    const newRoom = await RoomAPI.create(args)
    return {newRoom, venue_id: args.venue_id}
})

export const doFetchVenueById = createAsyncThunk('venue/fetchSingle', async venue_id => {
    return await VenueAPI.get_venue_by_id(venue_id)
})

export const doFetchVenueRooms = createAsyncThunk('venue/fetchSingleRooms', async venue_id => {
    return new Promise(res => VenueAPI.list_venue_rooms(venue_id).then(rooms => {
        res({rooms, venue_id})
    }))
})

export const doFetchVenues = createAsyncThunk('venue/fetch', async () => {
    return await VenueAPI.list_all_venues()
})
export const doCreateVenue = createAsyncThunk('venue/create', async ({name, address}) => {
    return await VenueAPI.create(name, address)
})


const venueSlice = createSlice({
    name: 'venue',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(startCreateNewVenue.fulfilled, (state, action) => {
                state.loading = false
                state.venueCreated = false
            })

            .addCase(doCreateVenueRoom.pending, (state, action) => {
                state.creatingRoomLoading = true
            })

            .addCase(doCreateVenueRoom.rejected, (state, action) => {
                state.creatingRoomLoading = false
                state.creatingRoomError = action.payload
            })

            .addCase(doCreateVenueRoom.fulfilled, (state, action) => {
                const {venue_id, newRoom} = action.payload
                console.log('Created new room:', action.payload)
                const existing = state.rooms[venue_id] || []
                state.rooms[venue_id] = [...existing, newRoom]
                state.creatingRoomLoading = false
            })

            .addCase(doFetchVenueRooms.fulfilled, (state, action) => {
                const {rooms, venue_id} = action.payload
                state.rooms[venue_id] = rooms
            })

            .addCase(doFetchVenueById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchVenueById.fulfilled, (state, action) => {
                const fetchedVenue = action.payload
                if (state.venues.find(v => v.id === fetchedVenue.id)) {
                    state.venues = state.venues.map(v => v.id === fetchedVenue.id ? fetchedVenue : v)
                } else {
                    state.venues = [...state.venues, action.payload]
                }
                state.loading = false
            })

            .addCase(doFetchVenues.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchVenues.fulfilled, (state, action) => {
                state.venues = action.payload
                state.loading = false
            })

            .addCase(doCreateVenue.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doCreateVenue.fulfilled, (state, action) => {
                state.venues = [...state.venues, action.payload]
                state.loading = false
                state.venueCreated = true
            })
    }
})

export default venueSlice.reducer

export const selectVenues = state => {
    return state.venue.venues
}

export const selectVenueLoading = state => {
    return state.venue.loading
}

export const selectVenueCreated = state => {
    return state.venue.venueCreated
}

export const selectVenueById = venue_id => state => {
    return state.venue.venues.find(v => v.id === venue_id)
}
export const selectVenueRooms = venue_id => state => {
    return state.venue.rooms[venue_id] || []
}
export const createRoomLoading = state => {
    return state.venue.creatingRoomLoading
}
export const createRoomError = state => {
    return state.venue.creatingRoomError
}
