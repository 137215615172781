import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import './event-page.css'
import {generatePath, Link, useNavigate, useParams} from "react-router-dom";
import {
    doCreateEventUser,
    doFetchEvent, doFetchEventRooms, doListUsersByEvent,
    selectEventById,
    selectEventLoading, selectEventRooms, selectUsersByEventId,
    updateEvent
} from "../../slice/eventSlice";
import {adminAPI, EventApi, exportAPI, VenueAPI} from "../../api";
import SimpleButton from "../../component/simple-button/SimpleButton";
import CenteredPopup from "../../component/centered-popup/CenteredPopup";
import InputField from "../../component/input-field/InputField";
import NewUser from "../../component/new-user/NewUser";
import RoomList from "../../component/room-list/RoomList";
import {doGetUser, selectUser} from "../../slice/userSlice";
import GuestList from "../../component/guest-list/GuestList";
import {doDeleteAllEventGuests, doFetchEventGuests, selectGuests} from "../../slice/guestSlice";
import ImportGuestList from "../../component/import-guest-list/ImportGuestList";
import ClickToShow from "../../component/click-to-show/ClickToShow";
import {prettyDate, prettyTimeDifference, toDanishDate} from "../../component/date_functions";
import AdminHeader from "../admin-page-wrapper/admin-header/AdminHeader";

export default function EventPage() {
    const [seatingOverview, setSeatingOverview] = useState([]);
    const [newPassword, setNewPassword] = useState('');
    const [passwordStatus, setPasswordStatus] = useState('');

    const [allVenueClients, setAllVenueClients] = useState(null);
    const [selectedUserToAdd, setSelectedUserToAdd] = useState('');

    const {event_id, venue_id} = useParams()

    // new customer
    const [showAddNewCustomer, setShowAddNewCustomer] = useState(false);

    // update event
    const [updatedEvent, setUpdatedEvent] = useState({});

    const availableSeats = useMemo(() => {
        return seatingOverview.reduce((prev, curr) => prev + curr.active_seats, 0)
    }, [seatingOverview])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const currentEvent = useSelector(selectEventById(event_id))
    const loading = useSelector(selectEventLoading)
    const users = useSelector(selectUsersByEventId(event_id))
    const rooms = useSelector(selectEventRooms)
    const currentUser = useSelector(selectUser)

    const guests = useSelector(selectGuests)

    const guestCount = useMemo(() => guests.reduce((prev, curr) => prev + curr.count, 0), [guests])

    useEffect(() => {
        dispatch(doGetUser())
        dispatch(doFetchEvent(event_id));
        dispatch(doListUsersByEvent(event_id));
        dispatch(doFetchEventRooms(event_id));
        dispatch(doFetchEventGuests(event_id))

        EventApi.list_seating_overview(event_id).then(setSeatingOverview);
        // EventApi.get_seating_validation(event_id).then(setSeatingValidation)
    }, [dispatch, event_id]);

    useEffect(() => {
        setUpdatedEvent(currentEvent)
    }, [currentEvent]);

    useEffect(() => {
        if (!showAddNewCustomer) return;
        VenueAPI.list_all_venue_clients(venue_id).then(setAllVenueClients)
    }, [showAddNewCustomer, venue_id]);

    const editLocalEvent = useCallback((key, newValue) => {
        setUpdatedEvent(prev => ({...prev, [key]: newValue}))
    }, []);

    const saveEvent = useCallback(() => {
        const changed = {}
        Object.keys(updatedEvent).forEach(k => {
            if (updatedEvent[k] !== currentEvent[k]) {
                changed[k] = updatedEvent[k]
            }
        })
        dispatch(updateEvent({event_id: currentEvent.id, values: changed}))
    }, [dispatch, currentEvent, updatedEvent]);

    const createEventUser = useCallback((values) => {
        dispatch(doCreateEventUser({
            ...values,
            event_id: currentEvent.id
        }))
    }, [dispatch, currentEvent]);

    if (!currentUser || !currentEvent || !updatedEvent) {
        return <div className='loading'>
            <p>Loading...</p>
        </div>
    }

    if (currentUser.role === 'venue-client') {
        // return navigate(generatePath('/event/:event_id/seating', {event_id}))
    }

    return (<div className={'event-page'}>
        <AdminHeader/>
        <div className='title-wrapper'>
            <h1>{currentEvent.title}</h1>
            <h3>{toDanishDate(currentEvent.date)}</h3>
        </div>

        <div className='horizontal'>
            <ClickToShow buttonText='Edit event' onOpen={_ => setUpdatedEvent(currentEvent)}>
                <h2>Edit event</h2>
                <InputField title='Title' value={updatedEvent.title} onChanged={v => editLocalEvent('title', v)}/>
                <InputField title='Date' type='date' value={toDanishDate(updatedEvent.date)}
                            onChanged={v => editLocalEvent('date', v)}/>
                <InputField title='Contact name' value={updatedEvent.contact_name}
                            onChanged={v => editLocalEvent('contact_name', v)}/>
                <InputField title='Contact email' value={updatedEvent.contact_email}
                            onChanged={v => editLocalEvent('contact_email', v)}/>
                <InputField title='Contact phone' value={updatedEvent.contact_phone}
                            onChanged={v => editLocalEvent('contact_phone', v)}/>
                <InputField title='Expected guests' value={updatedEvent.expected_guests}
                            onChanged={v => editLocalEvent('expected_guests', v)}/>

                <div className='rooms'>
                    <h2>Rooms</h2>
                    <label>Click to edit the room</label>
                    <RoomList rooms={rooms}/>
                </div>

                <SimpleButton big red value='Delete' onClick={_ => {
                    if (window.confirm('Are you sure you want to delete event, seating, event-room and guests?')) {
                        EventApi.delete(event_id).then(_ => {
                            navigate('/')
                        })
                    }
                }}/>

                <SimpleButton big onClick={saveEvent} value='Submit'/>
            </ClickToShow>
        </div>

        <div className='customer-wrapper'>
            <h2>Clients</h2>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>phone</th>
                    <th>Seated</th>
                    <th>Logged in</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {users.filter(u => u.role === 'venue-client').map(user => <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    {user.timestamp_seated &&
                        <td>{prettyTimeDifference(new Date(), new Date(user.timestamp_seated))}</td>}
                    {!user.timestamp_seated && <td> {'never'}</td>}
                    {user.login_timestamp &&
                        <td>{prettyTimeDifference(new Date(), new Date(user.login_timestamp))}</td>}
                    {!user.login_timestamp && <td>{'never'}</td>}
                    <td>
                        <ClickToShow buttonText='Set new password' buttonClassName='reset-btn' onOpen={_ => {
                            setNewPassword('')
                            setPasswordStatus('')
                        }}>
                            <h3>Resetting password for {user.name}</h3>
                            <br/>
                            <InputField value={newPassword} onChanged={setNewPassword} title='password'/>
                            <br/>
                            {passwordStatus === 'OK' && <p>Updated password.</p>}
                            <SimpleButton loading={passwordStatus === 'loading'} disabled={newPassword.length <= 3}
                                          value='Submit' onClick={_ => {
                                setPasswordStatus('loading')
                                adminAPI.patch_user(user.id, newPassword).then(_ => setPasswordStatus('OK'))
                            }}/>
                        </ClickToShow></td>
                    <td>
                        <SimpleButton value='remove from event' className='reset-btn' onClick={_ => {
                            if (window.confirm('Remove user from event?')) {
                                adminAPI.remove_user_from_event(user.id, event_id).then(_ => {
                                    dispatch(doListUsersByEvent(event_id));
                                })
                            }
                        }}/>
                    </td>
                </tr>)}
                </tbody>
            </table>

            <div className='add-new' onClick={_ => setShowAddNewCustomer(true)}>
                <p>+</p>
            </div>
        </div>

        <div className='event-note'>
            <h3>Note for the client:</h3>
            <textarea value={updatedEvent.note_to_client ?? ''}
                      onChange={v => setUpdatedEvent(prev => ({...prev, note_to_client: v.target.value}))}/>
            <SimpleButton value='Save' onClick={saveEvent}
                          disabled={updatedEvent.note_to_client === currentEvent.note_to_client}/>
        </div>

        <div className='seating-overview-wrapper'>
            <h2>Seating</h2>
            <table>
                <tbody>
                <tr>
                    <th>Expected guests</th>
                    <td>{currentEvent.expected_guests}</td>
                </tr>
                <tr>
                    <th>Guests imported by customer:</th>
                    <td>{guestCount}</td>
                </tr>
                <tr>
                    <th>Seats available with current setup:</th>
                    <td>{availableSeats}</td>
                </tr>
                </tbody>
            </table>
            <Link to={`/venue/${venue_id}/event/${event_id}/seating`}>Go to seating</Link>
            <br/>
            <br/>
            {currentEvent.locked_seating ? <h2>Seating is <b>locked</b></h2> : <h2>Seating is open</h2>}
            {currentEvent.locked_seating ?
                <SimpleButton value='Open seating' onClick={_ => {
                    dispatch(updateEvent({
                        event_id: currentEvent.id, values: {
                            locked_seating: false
                        }
                    }))
                }}/> :
                <SimpleButton red value='Lock seating for venue clients' onClick={_ => {
                    dispatch(updateEvent({
                        event_id: currentEvent.id, values: {
                            locked_seating: true
                        }
                    }))
                }}/>}
        </div>
        <hr/>
        <ImportGuestList/>
        <GuestList displaySeated editable showSearch guests={guests}/>
        <br />
        <br />
        {currentEvent && <SimpleButton value='Delete all guests' red onClick={_ => {
            if (!window.confirm(`Delete ALL guests for ${currentEvent.title}?`)) return;
            dispatch(doDeleteAllEventGuests(currentEvent.id))
        }}/>}

        <CenteredPopup showPopup={showAddNewCustomer} closePopup={setShowAddNewCustomer}>
            <div className='horizontal'>
                <NewUser title='Ny kunde' submit={createEventUser}/>
                <div className='add existing'>
                    <h2>Eller tilføj eksisterende</h2>
                    {allVenueClients && <InputField options={allVenueClients.map(e => e.email)}
                                                    value={selectedUserToAdd ? selectedUserToAdd.name : ''}
                                                    onChanged={email => setSelectedUserToAdd(allVenueClients.find(c => c.email === email))}
                                                    onlySelectableOptions/>}
                    <SimpleButton value='Add user to event' big disabled={!selectedUserToAdd} onClick={_ => {
                        adminAPI.add_user_to_event(selectedUserToAdd.id, event_id).then(_ => {
                            dispatch(doListUsersByEvent(event_id));
                        })
                    }}/>
                </div>
            </div>
        </CenteredPopup>
    </div>)
}
