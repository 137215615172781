import React, {useCallback} from 'react'
import {useSelector} from "react-redux";
import {
    selectSelectedEvent,
} from "../../../slice/userSlice";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {useNavigate} from "react-router-dom";
import './venue-client-seating-page.css'

export default function VenueClientSeatingPage() {
    const selectedEvent = useSelector(selectSelectedEvent);

    const navigate = useNavigate()
    const gotoSeating = useCallback(() => {
        navigate(`/event/${selectedEvent.id}/seating`)
    }, [navigate, selectedEvent])

    return (<div className='venue-client-seating-page'>
        <h2>Before you get started with seating, you should read these tips:</h2>

        <div className='tip-wrapper'>
            <div className='tip'>
                <h3>Make sure your tables are not completely full</h3>
                <p>It gives your guests a better experience if they have a little more room
                    for their elbows.</p>
            </div>
            <div className='tip'>
                <h3>Fill from the lower floors and up.</h3>
                <p>All seats are good - but there
                    are still some seats that have
                    a better view than others.</p>
            </div>
            <div className='tip'>
                <h3>Red, Yellow, Green</h3>
                <ul>
                    <li>Red - table is full</li>
                    <li>Yellow - some seated</li>
                    <li>Green - empty</li>
                </ul>
            </div>
        </div>

        <h2>Alright - let's go!</h2>
        {selectedEvent && !!selectedEvent.locked_seating &&
            <h3 style={{color: 'red', fontWeight: 'bold'}}>Seating is locked for this event - please contact us if you think this is a mistake.</h3>}
        {selectedEvent && <SimpleButton className='goto-seating-btn' disabled={selectedEvent.locked_seating}
                                        value='Go to Seating tool'
                                        onClick={gotoSeating} big/>}

        <div className='grower'/>
        {selectedEvent && <div className='contact-wrapper'>
            <div className='vertical'>
                <h3>{selectedEvent.contact_name}</h3>
                <h3>{selectedEvent.contact_email}</h3>
                <h3>{selectedEvent.contact_phone}</h3>
            </div>
        </div>}
    </div>)
}
