import React, {useEffect, useState} from 'react'
import './signup-invitation-list.css'
import {Link, useParams} from "react-router-dom";
import {invitationAdminAPI, invitationAPI} from "../../../../api";
import {prettyDate} from "../../../../component/date_functions";
import CenteredPopup from "../../../../component/centered-popup/CenteredPopup";
import InputField from "../../../../component/input-field/InputField";
import SimpleButton from "../../../../component/simple-button/SimpleButton";


export default function SignupInvitationList() {
    const {event_id} = useParams()

    const [activeInvitations, setActiveInvitations] = useState([]);
    const [questions, setQuestions] = useState([]);

    const [creatingInvitation, setCreatingInvitation] = useState(false);
    const [guestEmail, setGuestEmail] = useState('');
    const [guestName, setGuestName] = useState('');

    useEffect(() => {
        if (!event_id) return;
        invitationAPI.list_invitations(event_id).then(setActiveInvitations)
        invitationAdminAPI.list_questions(event_id).then(setQuestions)
    }, [event_id]);

    return (<div className={'signup-invitation-list'}>
        <CenteredPopup showPopup={creatingInvitation} closePopup={setCreatingInvitation}>
            <h3>Creating new invitation</h3>
            <InputField title='email' value={guestEmail} onChanged={setGuestEmail} />
            <InputField title='name' value={guestName} onChanged={setGuestName} />
            <SimpleButton disabled={!guestEmail || !guestName} onClick={_ => {
                invitationAdminAPI.create_invitation(event_id, guestEmail, guestName).then(_ => {

                })
            }} value='Create' />
        </CenteredPopup>
        <SimpleButton className='create-invitation' value='Create invitation' onClick={_ => setCreatingInvitation(true)} />

        <table>
            <thead><tr>
                <th>Seen</th>
                <th>Invitation name</th>
                <th>Email</th>
                <th>Token</th>
                {questions.map(q => <th key={q.id}>{q.question_text}</th>)}
            </tr></thead>
            <tbody>
            {activeInvitations.map(ai => <tr key={ai.token}>
                <td>{ai.ts_seen !== null ? prettyDate(ai.ts_seen) : 'not seen'}</td>
                <td>{ai.guest_name}</td>
                <td>{ai.email}</td>
                <td>{ai.token}</td>
                {questions.map(q => <td key={q.id}>{ai.answers ? (q.id in ai.answers ? ai.answers[q.id] : '') : ''}</td>)}
            </tr>)}
            </tbody>
        </table>
    </div>)
}
