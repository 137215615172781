import React, {useEffect} from 'react'
import './admin-page-wrapper.css'
import {useDispatch, useSelector} from "react-redux";
import {
    doFetchVenues,
    selectVenueLoading,
    selectVenues
} from "../../slice/venueSlice";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {doFetchVenueEvents, selectVenueEvents} from "../../slice/eventSlice";
import SimpleButton from "../../component/simple-button/SimpleButton";
import ClickToShow from "../../component/click-to-show/ClickToShow";
import {doGetUser, selectUser} from "../../slice/userSlice";

export default function AdminPageWrapper({className, children}) {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    let {event_id, venue_id} = useParams();
    event_id = parseInt(event_id)
    venue_id = parseInt(venue_id)

    const user = useSelector(selectUser)
    const venues = useSelector(selectVenues)
    const venueLoading = useSelector(selectVenueLoading)
    const events = useSelector(selectVenueEvents(venue_id));

    useEffect(() => {
        dispatch(doGetUser())
    }, [dispatch]);

    useEffect(() => {
        if (venues.length > 0) return;
        if (venueLoading) return;
        console.log('fetch from admin page', venues.length, venueLoading)
        dispatch(doFetchVenues())
    }, [dispatch, venues, venueLoading]);

    useEffect(() => {
        if (venues.length === 0 || !!venue_id) return;
        navigate(`/venue/${venues[0].id}`)
    }, [venues, venue_id]);

    useEffect(() => {
        if (!venue_id) return;
        dispatch(doFetchVenueEvents(venue_id))
    }, [venue_id]);

    return (<div className='admin-page-wrapper'>
        <div className='left-menu'>
            {user && user.role === 'developer' && <SimpleButton red value='admin' onClick={_ => navigate(('/admin'))} />}
            {venues.map(venue => <div
                className={`venue`}
                key={venue.id}>
                <div className={`header-wrapper ${venue_id === venue.id ? 'selected' : ''}`}
                     onClick={_ => {
                         navigate(`/venue/${venue.id}`)
                     }}>
                    <h2>{venue.name}</h2>
                </div>
                {venue_id === venue.id && <div className='event-wrapper'>
                    <ClickToShow buttonClassName='old-event-btn' buttonText='afholdte events'>
                        <h2>Tidligere afholdte events</h2>
                        <br />
                        {events.filter(e => new Date(e.date).setHours(23) < new Date()).map(e => <div
                            key={e.id}
                            className={`event ${event_id === e.id ? 'selected' : 'selectable'}`}
                            onClick={_ => {
                                navigate(`/venue/${venue_id}/event/${e.id}`)
                            }}>
                            <p>{e.title}</p>
                            <sub>{new Date(e.date).toDateString()}</sub>
                            <div className='hover-bar'/>
                        </div>)}
                    </ClickToShow>
                    {events.filter(e => {
                        if (e.id === event_id) return true;
                        return new Date(e.date).setHours(23) > new Date()
                    }).map(e => <div
                        key={e.id}
                        className={`event ${event_id === e.id ? 'selected' : 'selectable'}`}
                        onClick={_ => {
                            navigate(`/venue/${venue_id}/event/${e.id}`)
                        }}>
                        <p>{e.title}</p>
                        <sub>{new Date(e.date).toDateString()}</sub>
                        <div className='hover-bar'/>
                    </div>)}
                </div>}
            </div>)}
            <div className='grower' />
            <SimpleButton value='logout' onClick={_ => navigate('/logout')} />
        </div>

        <div className={`scrollable ${className}`}>
            <Outlet />
        </div>
    </div>)
}
