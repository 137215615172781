import React, {useCallback, useState} from 'react'
import './multiple-choice.css'
import cross from '../../assets/clear-24px.svg'


export default function MultipleChoice({choices, value, onChanged, onChoicesChanged, editable = false}) {
    const [addingNewText, setAddingNewText] = useState(null);

    const addNewAsChoice = useCallback(() => {
        onChoicesChanged([...choices, addingNewText])
        setAddingNewText(null)
    }, [addingNewText, choices, onChoicesChanged]);

    return (<div className='multiple-choice'>
        {choices.map(choice => <label
            onClick={_ => !editable && onChanged(choice)}
            className={value === choice ? 'selected' : ''} key={choice}>{choice}
            {editable && <img alt='remove' src={cross} onClick={_ => {
                onChoicesChanged(choices.filter(c => c !== choice))
            }}/>}
        </label>)}
        {editable && (addingNewText === null ?
            <label onClick={_ => setAddingNewText('')}>+</label> :
            <input
                value={addingNewText}
                onChange={e => setAddingNewText(e.target.value)}
                onKeyUp={e => {
                    if (e.code === 'Enter') {
                        addNewAsChoice()
                    }
                }}
                onBlur={addNewAsChoice}/>)}
    </div>)
}
