import React from 'react'
import {Link, Navigate, useLocation} from "react-router-dom";
import './require-auth.css'

export default function RequireAuth(props) {
    const expires = localStorage.getItem('tokenExpires')
    const location = useLocation()

    if (new Date(expires) < new Date()) {
        if (location.pathname.indexOf('logout') === -1) {
            localStorage.setItem('redirAfterLogin', location.pathname)
        } else {
            localStorage.setItem('redirAfterLogin', '')
        }
        return <Navigate to='/login' />
    }

    return props.children
}
