import React from 'react'
import {useNavigate} from "react-router-dom";
import './room-list.css'


export default function RoomList({rooms}) {
    const navigate = useNavigate();
    return (<div className='room-list'>
        {rooms.map(room => <div className='room' key={room.id}
                                onClick={_ => {
                                    navigate(`/room/${room.id}`)
                                }}>
            <h2>{room.name}</h2>
            <div className='room-preview'>
                <img src={room.signed} alt={`preview of ${room.name}`}/>
            </div>
        </div>)}
    </div>)
}
