import React, {useEffect, useMemo, useState} from 'react'
import './event-page.css'
import {generatePath, Link, useNavigate, useParams} from "react-router-dom";
import {EventApi, exportAPI} from "../../api";
import AdminHeader from "../admin-page-wrapper/admin-header/AdminHeader";
import SimpleButton from "../../component/simple-button/SimpleButton";
import {useSelector} from "react-redux";
import {selectSelectedEvent} from "../../slice/userSlice";

export default function DoorList({withAdminHeader=true}) {
    const [guestList, setGuestList] = useState([]);
    const [showLastName, setShowLastName] = useState(true);
    const {event_id} = useParams()

    const selectedEvent = useSelector(selectSelectedEvent)

    const targetEventId = useMemo(() => {
        if (selectedEvent) return selectedEvent.id
        return event_id;
    }, [event_id, selectedEvent]);

    useEffect(() => {
        if (!targetEventId) return;
        exportAPI.export_guest_list_by_event_id(targetEventId).then(gs => {
            setGuestList([...gs].map(g => {
                const lastName = g.name.substring(g.name.indexOf(' '))

                return {...g, lastName}
            }).sort((a, b) => {
                return a.lastName.localeCompare(b.lastName)
            }))
        })
    }, [targetEventId]);

    const copyable = useMemo(() => {
        const rows = guestList.map(g => {
            if (showLastName) return [g.Bordnummer, g.antal, g.lastName, g.name, g.company].join('\t')

            return [g.Bordnummer, g.antal, g.name, g.company].join('\t')
        })
        if (showLastName) return ['Bordnummer\tAntal\tEfternavn\tNavn\tFirma', ...rows].join('\n')

        return ['Bordnummer\tAntal\tNavn\tFirma', ...rows].join('\n')
    }, [guestList, showLastName]);

    return <div style={{overflowY: 'scroll', height: '100%'}}>
        {withAdminHeader && <AdminHeader/>}
        <h1>Gæsteliste til døren</h1>

        <div>
            <input id='show-last-name-chkbox' type='checkbox' checked={showLastName}
                   onChange={e => setShowLastName(e.target.checked)} title='Show last name?'/>
            <label htmlFor='show-last-name-chkbox'>Show last name:</label>
        </div>
        <br />

        <SimpleButton value='copy' onClick={() => {
            navigator.clipboard.writeText(copyable)
        }}/>
        <table style={{width: '100%', maxWidth: '900px', margin: '16px'}}>
            <thead>
            <tr style={{textAlign: 'left'}}>
                <th>Bordnummer</th>
                <th>Antal</th>
                {showLastName && <th>Efternavn</th>}
                <th>Navn</th>
                <th>Firma</th>
            </tr>
            </thead>
            <tbody>
            {guestList.map(g => <tr key={g.id}>
                <td>{g.Bordnummer}</td>
                <td>{g.antal}</td>
                {showLastName && <td>{g.lastName}</td>}
                <td>{g.name}</td>
                <td>{g.company}</td>
            </tr>)}
            </tbody>
        </table>
    </div>
}
